import axiosInstance from "./AxiosInstance";

const apiNotification = "api/notification";

// Fetch notifications
export function getNotification() {
  return axiosInstance.get(apiNotification)
    .then(response => {
      console.log("Full API response for notifications:", response);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error("Invalid response from API");
      }
    })
    .catch(error => {
      console.error("Failed to fetch notifications:", error.message || error);
      throw error;
    });
}

export function markNotificationsAsRead(ids, isRead = true) {
  const payload = {
    ids: Array.isArray(ids) ? ids : [ids],
    is_read: isRead,
  };
  
  return axiosInstance.put(apiNotification, payload)
    .then(response => {
      console.log("Response for marking notifications as read:", response);
      return response;
    })
    .catch(error => {
      console.error("Failed to mark notifications as read:", error.message || error);
      throw error;
    });
}
