import axiosInstance from "./AxiosInstance";

const apiUrl = "/api/event";
const apiOpenUrl = "/api/open_event";


export function getOpenEventList(page, page_size, query) {
  return axiosInstance.get(apiOpenUrl, {
    params: {
      page,
      page_size,
      query,
    },
    timeout: 5000,
  });
}

// Get a specific open event by ID
export function getOpenEvent(id) {
  return axiosInstance.get(`${apiOpenUrl}/${id}`);
}

// Get the list of all events with pagination and search query
export function getEventList(page, page_size, query) {
  return axiosInstance.get(apiUrl, {
    params: {
      page,
      page_size,
      query,
    },
  });
}

// Get a specific event by ID
export function getEvent(id) {
  return axiosInstance.get(`${apiUrl}/${id}`);
}

// Create a new event
export function createEvent(data) {
  return axiosInstance.post(apiUrl, data);
}

// Update an event
export function updateEvent(id, data) {
  return axiosInstance.put(`${apiUrl}/${id}`, data);
}

// Delete an event by ID
export function deleteEvent(id) {
  return axiosInstance.delete(`${apiUrl}/${id}`);
}
