import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import RazorpayPayment from "./RazorPay";
import { format } from "date-fns";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { bookingRoom } from "../../../../services/RoomService";

const UserPaymentMode = ({
  orderId,
  handleCancelBook,
  coupon,
  setShowConfirmPage,
  bookInput,
  room,
}) => {
  const [isBookingLoading, setIsBookingLoading] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("Online");
  const [selectedOfflinePaymentMode, setSelectedOfflinePaymentMode] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  const navigate = useNavigate();

  const handlePaymentModeSelect = (mode) => {
    const descriptions = {
      Cash: "Pay by cash at the venue.",
      PhonePe: "Transfer via PhonePe.",
      DebitCard: "Pay using your Debit Card.",
      GooglePay: "Transfer via GooglePay.",
      Paytm: "Transfer via Paytm.",
      NEFT: "Transfer via NEFT.",
      IMPS: "Transfer via IMPS.",
    };
    setAlertMessage(descriptions[mode]);
    setSelectedOfflinePaymentMode(mode);
  };

  const calculateDiscountedPrice = () => {
    let discount = 0;
    if (coupon) {
      if (coupon.discount_type === "Flat") {
        discount = coupon.discount_value;
      } else if (coupon.discount_type === "Percentage") {
        const percentageDiscount = (room.price * 1.18 * coupon.discount_value) / 100;
        discount = Math.min(percentageDiscount, coupon.maximum_discount_value);
      }
    }
    return Math.ceil(room.price * 1.18 - discount);
  };

  const handleConfirmBooking = async (e) => {
    e.preventDefault();
    if (selectedPaymentMethod === "Offline" && !selectedOfflinePaymentMode) {
      toast.error("Please select a payment source for Offline Payment.");
      return;
    }

    try {
      setIsBookingLoading(true);
      const { room_id, booking_date, booking_time, on_behalf_id } = bookInput;

      const payload = {
        room_id,
        booking_date: format(booking_date, "yyyy-MM-dd"),
        booking_time,
        on_behalf_id,
        payment_mode: selectedPaymentMethod,
        paymentSource: selectedOfflinePaymentMode || null,
        coupon_id: coupon ? coupon.id : null,
      };

      const resp = await bookingRoom(payload);
      toast.success(resp.data.message);
      navigate(`/room-details/${room_id}`);
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    } finally {
      setIsBookingLoading(false);
      setShowConfirmPage(false);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-6 mt-3 mb-4 mb-sm-0">
          <div>
            <strong>Booking Date: </strong>
            <span className="title text-dark">
              {format(bookInput.booking_date, "yyyy-MM-dd")}
            </span>
          </div>
          <div>
            <strong>Booking Time: </strong>
            <span className="title text-dark">{bookInput.booking_time}</span>
          </div>
        </div>
        <div className="col-md-6">
          <div className="payment-options d-flex flex-column align-items-center mb-3">
            <div
              className={`payment-option-wrapper d-flex justify-content-center ${
                selectedPaymentMethod === "Offline" ? "mb-0" : "mb-3"
              }`}
            >
              <div className="payment-option mx-2">
                <input
                  type="radio"
                  id="Online-payment"
                  name="payment-method"
                  className="me-2"
                  value="Online"
                  checked={selectedPaymentMethod === "Online"}
                  onChange={() => {
                    setSelectedPaymentMethod("Online");
                    setAlertMessage("");
                  }}
                />
                <label htmlFor="Online-payment" className="mb-0">
                  Online
                </label>
              </div>

              <div className="payment-option mx-2">
                <input
                  type="radio"
                  id="Offline-payment"
                  name="payment-method"
                  className="me-2"
                  value="Offline"
                  checked={selectedPaymentMethod === "Offline"}
                  onChange={() => setSelectedPaymentMethod("Offline")}
                />
                <label htmlFor="Offline-payment" className="mb-0">
                  Offline
                </label>
              </div>
            </div>
            {selectedPaymentMethod === "Offline" && (
              <select
                value={selectedOfflinePaymentMode}
                onChange={(e) => handlePaymentModeSelect(e.target.value)}
                className="custom-select"
              >
                <option value="">Select Payment Mode</option>
                <option value="Cash">Cash</option>
                <option value="PhonePe">PhonePe</option>
                <option value="DebitCard">Debit Card</option>
                <option value="GooglePay">Google Pay</option>
                <option value="Paytm">Paytm</option>
                <option value="NEFT">NEFT</option>
                <option value="IMPS">IMPS</option>
              </select>
            )}
          </div>

          <div className="d-flex align-items-center justify-content-center">
            {selectedPaymentMethod === "Online" ? (
              <RazorpayPayment
                price={calculateDiscountedPrice()}
                roomId={room?.id}
                bookingDate={
                  bookInput.booking_date &&
                  format(bookInput.booking_date, "dd/MM/yyyy")
                }
                bookingTime={bookInput.booking_time}
                orderId={orderId}
                paymentSource="Razorpay"
                paymentMode="Online"
                coupon_id={coupon?.id}
              />
            ) : (
              <button
                type="button"
                className="btn btn-sm btn-success me-2"
                onClick={handleConfirmBooking}
                disabled={isBookingLoading}
              >
                {isBookingLoading ? (
                  <span>
                    <Spinner animation="border" size="sm" /> Confirming...
                  </span>
                ) : (
                  "Confirm"
                )}
              </button>
            )}
            <button
              type="button"
              className="btn btn-sm btn-danger ms-2"
              onClick={handleCancelBook}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          {alertMessage && (
            <div className="alert alert-info mt-5" role="alert">
              <strong>{alertMessage}</strong>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default UserPaymentMode;
