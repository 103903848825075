import React from 'react';

const PriceTable = ({ room, selectedCoupon }) => {
  const priceWithGST = Math.ceil(room?.price * 1.18);
  let discountAmount = 0;

  if (selectedCoupon) {
    if (selectedCoupon.discount_type === "Flat") {
      discountAmount = Math.ceil(selectedCoupon.discount_value);
    } else if (selectedCoupon.discount_type === "Percentage") {
      discountAmount = Math.ceil((room?.price * selectedCoupon.discount_value) / 100);

      if (selectedCoupon.maximum_discount_value && discountAmount > selectedCoupon.maximum_discount_value) {
        discountAmount = selectedCoupon.maximum_discount_value;
      }
    }
  }

  // Calculate the total price considering the discount and GST
  const totalAfterDiscount = Math.ceil(room?.price - discountAmount);
  const netPayableTotal = Math.ceil(totalAfterDiscount * 1.18); // Adding GST after discount

  return (
    <div className="card">
      <div className="table-responsive">
        <table className="table table-hover payment-summary-table">
          <thead className="table-header">
            <tr>
              <th>Room</th>
              <th className="text-center">Price</th>
              <th className="text-center">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="col-md-9 text-center">
                <em>{room?.name}</em>
              </td>
              <td className="col-md-1 text-center">
                ₹{Math.ceil(room?.price)}
              </td>
              <td className="col-md-1 text-center">
                ₹{Math.ceil(room?.price)}
              </td>
            </tr>
            <tr>
              <td></td>
              <td className="text-right">
                <p>
                  <strong>Subtotal: </strong>
                </p>
                <p>
                  <strong>GST (18%): </strong>
                </p>
              </td>
              <td className="text-center">
                <p>
                  <strong>₹{Math.ceil(room?.price)}</strong>
                </p>
                <p>
                  <strong>₹{Math.ceil(room?.price * 0.18)}</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td className="text-center">
                {selectedCoupon && (
                  <>
                    <p className="coupon-applied-text">
                      Coupon Applied
                    </p>
                    <p className="coupon-code">
                      <em>{selectedCoupon.code}</em>
                    </p>
                  </>
                )}
              </td>
              <td className="text-right">
                <p>
                  <strong>Total Amount: </strong>
                </p>
                {selectedCoupon && (
                  <p>
                    <strong>Coupon Discount: </strong>
                  </p>
                )}
              </td>
              <td className="text-center">
                <p>
                  <strong>₹{priceWithGST}</strong>
                </p>
                {selectedCoupon && (
                  <p>
                    <strong>₹{discountAmount}</strong>
                  </p>
                )}
              </td>
            </tr>
            <tr>
              <td></td>
              <td className="text-right total-payable-label">
                <h4>
                  <strong>Net Payable Total: </strong>
                </h4>
              </td>
              <td className="text-center text-danger total-payable-amount">
                <h4>
                  <strong>₹{netPayableTotal}</strong>
                </h4>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PriceTable;
