import React, { useState, useEffect } from "react";
import { Polar } from "react-chartjs-2";
import classnames from "classnames";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Row,
  Col
} from "reactstrap";
import { getRoomTypeGraph } from "../../services/DashBoardService";

// Updated vibrant colors
const vibrantColors = [
 "#FF6F61", // Coral
  "#008080", // Teal
  "#FFF700", // Lemon Yellow
  "#00BFFF", // Deep Sky Blue
];

const colors = {
  gray: {
    100: "#f6f9fc",
    200: "#e9ecef",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#8898aa",
    700: "#525f7f",
    800: "#32325d",
    900: "#212529",
  },
  theme: {
    default: "#172b4d",
    primary: "#5e72e4",
    secondary: "#f4f5f7",
    info: "#11cdef",
    success: "#2dce89",
    danger: "#f5365c",
    warning: "#fb6340",
  },
  black: "#12263F",
  white: "#FFFFFF",
  transparent: "transparent",
};

const BookingRoomGraph = () => {
  const [dataset, setDataSet] = useState(null);
  const [labels, setLabels] = useState([]);
  const [bgColors, setBgColors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [timePeriod, setTimePeriod] = useState("weekly");

  const toggleTimePeriod = (tp) => {
    setTimePeriod(tp);
  };

  useEffect(() => {
    setIsLoading(true);
    getRoomTypeGraph(timePeriod)
      .then((resp) => {
        const { results } = resp.data;
        const result = {
          ...results,
          datasets: [
            {
              ...results.datasets[0],
              backgroundColor: vibrantColors,
              borderColor: "#ffffff",
              borderWidth: 2,
            },
          ],
        };

        setLabels(results.labels);
        setBgColors(vibrantColors);
        setDataSet(result);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error.response ? error.response.data.message : error);
        setIsLoading(false);
      });
  }, [timePeriod]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      animateScale: true,
      animateRotate: true,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: "rgba(0,0,0,0.8)",
        titleFont: { size: 14, weight: "bold" },
        bodyFont: { size: 12 },
        bodyColor: "#fff",
        padding: 10,
      },
    },
  };

  return (
    <Card className="shadow CardNEW" style={{ backgroundColor: "#ecf0f1", borderRadius: "20px", padding: "20px", position: "relative" }}>
      <CardHeader
        className="bg-white shadow-sm mb-3"
        style={{ borderBottom: "none", padding: "15px", borderRadius: "20px" }}
      >
        <Row className="align-items-center justify-content-center">
          <div className="col text-left">
            <h2 className="mb-0" style={{ color: "#2c3e50", textShadow: "1px 1px 3px rgba(0, 0, 0, 0.2)" }}>
              <strong>Booking Room Graph</strong>
            </h2>
          </div>
          <div className="col">
            <Nav className="justify-content-end" pills>
              <NavItem>
                <NavLink
                  className={classnames("py-1 px-3", { active: timePeriod === "yearly" })}
                  style={{
                    backgroundColor: timePeriod === "yearly" ? "#d63031" : "transparent",
                    color: "#2c3e50",
                    borderRadius: "10px",
                    transition: "0.3s ease",
                  }}
                  onClick={() => toggleTimePeriod("yearly")}
                >
                  Y
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames("py-1 px-3", { active: timePeriod === "monthly" })}
                  style={{
                    backgroundColor: timePeriod === "monthly" ? "#6c5ce7" : "transparent",
                    color: "#2c3e50",
                    borderRadius: "10px",
                    transition: "0.3s ease",
                  }}
                  onClick={() => toggleTimePeriod("monthly")}
                >
                  M
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames("py-1 px-3", { active: timePeriod === "weekly" })}
                  style={{
                    backgroundColor: timePeriod === "weekly" ? "#00b894" : "transparent",
                    color: "#2c3e50",
                    borderRadius: "10px",
                    transition: "0.3s ease",
                  }}
                  onClick={() => toggleTimePeriod("weekly")}
                >
                  W
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </Row>
      </CardHeader>
      <CardBody
        className="p-sm-4 p-0"
        style={{
          position: "relative",
          height: "400px",
          backgroundColor: "#ffffff",
          borderRadius: "15px",
          boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
          overflow: "hidden",
        }}>
        {isLoading ? (
          <div className="text-center my-5">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : dataset ? (
          <>
            <div className="chart" style={{ height: "280px", position: "relative" }}>
              <Polar data={dataset} options={options} />
            </div>
            <div className="custom-legend">
              <Row>
                {labels.map((label, index) => (
                  <Col xs="6" sm="3" key={index} className="mb-3">
                    <div className="legend-item d-flex align-items-center">
                      <div
                        className="me-3"
                        style={{
                          width: "15px",
                          height: "15px",
                          backgroundColor: bgColors[index],
                          borderRadius: "4px",
                          border: "2px solid #fff",
                          transition: "transform 0.2s ease",
                        }}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.transform = "scale(1.1)";
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.transform = "scale(1)";
                        }}
                      />
                      <span
                        style={{
                          fontSize: "11px",
                          color: "#333",
                        }}
                      >
                        {label}
                      </span>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </>
        ) : (
          <div className="text-center py-8">
            <i
              className="fas fa-search-minus"
              style={{ fontSize: "1.5rem", opacity: 0.8, color: "#17a2b8" }}
            ></i>
            <h3 style={{ opacity: 0.5 }}>No Booking Occurs</h3>
          </div>
        )}
      </CardBody>
      <style jsx>{`
        .custom-legend {
          margin-top: 20px;
        }
        .legend-item {
          display: flex;
          align-items: center;
        }
        .legend-item div {
          transition: transform 0.3s ease;
        }
      `}</style>
    </Card>
  );
};

export default BookingRoomGraph;
