import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { updateMemberAPI } from "../../services/VerifyMemberService";

const INPUT_FIELD_LIST = [
  { label: "First Name", name: "first_name", type: "text", placeholder: "Enter your first name" },
  { label: "Last Name", name: "last_name", type: "text", placeholder: "Enter your last name" },
  { label: "Membership ID", name: "membership_id", type: "text", placeholder: "Enter membership ID" },
  {
    label: "Membership Type",
    name: "membership_type",
    type: "select",
    placeholder: "Select membership type",
    options: [
      { label: "SAIL RSP Member", value: "SAIL RSP Member" },
      { label: "GOVERNMENT Member", value: "GOVERNMENT Member" },
      { label: "PRIVATE Member", value: "PRIVATE Member" },
      { label: "Honorary Member", value: "Honorary Member" },
    ],
  },
  { label: "Email ID", name: "email", type: "email", placeholder: "Enter your email address" },
  { label: "Phone Number", name: "phone_number", type: "tel", placeholder: "Enter your phone number" },
 ];

const initialFormState = {
  first_name: "",
  last_name: "",
  membership_id: "",
  membership_type: "",
  email: "",
  phone_number: "",
};

const EditMemberPopup = ({ member, data, setData, onClose }) => {
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [formData, setFormData] = useState(initialFormState);
  const [errors, setErrors] = useState(initialFormState);

  useEffect(() => {
    if (member) {
      const { id, first_name, last_name, email, phone_number, membership_id, membership_type } = member;
      setFormData({
        member_id: id, first_name, last_name, email, phone_number, membership_id, membership_type
      });
    }
  }, [member]);

  const handleValidate = (name, value) => {
    if (name === 'email') {
      if (!value) {
        setErrors({ ...errors, [name]: 'Email is required' });
        return false;
      } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
        setErrors({ ...errors, [name]: 'Invalid Email format.' });
        return false;
      } else {
        setErrors({ ...errors, [name]: '' });
        return true;
      }
    } else if (name === 'phone_number') {
      if (!value) {
        setErrors({ ...errors, [name]: 'Phone number is required' });
        return false;
      } else if (!/^\d{10}$/.test(value)) {
        setErrors({ ...errors, [name]: 'Invalid phone number.' });
        return false;
      } else {
        setErrors({ ...errors, [name]: '' });
        return true;
      }
    } else {
      const errorMsgObj = {
        first_name: "First name",
        last_name: "Last name",
        membership_id: "Membership Id",
        membership_type: "Membership type",
      };
      if (!value) {
        setErrors({ ...errors, [name]: `${errorMsgObj[name]} is required` });
        return false;
      } else {
        setErrors({ ...errors, [name]: '' });
        return true;
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    handleValidate(name, value);
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isSubmitLoading) {
      return;
    }
    const isValidInput = Object.keys(formData).map(key => handleValidate(key, formData[key])).every(member => member);
    if (isValidInput) {
      setIsSubmitLoading(true);
      updateMemberAPI(formData)
        .then((resp) => {
          const { results, message } = resp.data;
          const updatedData = data.map((d) => d.id === results.id ? results : d);
          setData(updatedData);
          toast.success(message);
          onClose();
        })
        .catch((error) => {
          if (error.response) {
            toast.error(error.response.data.message);
          }
        })
        .finally(() => {
          setIsSubmitLoading(false);
        });
    }
  };

  return (
    <div className="popup">
      <form className="popup-inner" onSubmit={handleSubmit}>
        <div className="popup-header">
          <h2>Modify Member</h2>
        </div>
        <div className="popup-body">
          {INPUT_FIELD_LIST.map(({ label, name, type, placeholder, options }) => (
            <div className="form-group" key={name}>
              <label htmlFor={name}>{label}:</label>
              {type === "select" ? (
                <div className="input-group">
                  <select
                    id={name}
                    name={name}
                    value={formData[name]}
                    onChange={handleChange}
                    className="custom-select"
                    onBlur={(e) => handleValidate(e.target.name, e.target.value)}
                  >
                    <option value="" disabled>{placeholder}</option>
                    {options.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  {errors[name] && (
                    <div className="form-error">{errors[name]}</div>
                  )}
                </div>
              ) : (
                <div className="input-group">
                  <input
                    type={type}
                    name={name}
                    value={formData[name]}
                    onChange={handleChange}
                    onBlur={(e) => handleValidate(e.target.name, e.target.value)}
                    placeholder={placeholder}
                  />
                  {errors[name] && (
                    <div className="form-error">{errors[name]}</div>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="btn-container">
          <button type="submit" className="submit-btn" disabled={isSubmitLoading}>Update</button>
          <button type="button" onClick={onClose} className="cancel-btn">Cancel</button>
        </div>
      </form>
    </div>
  );
};

export default EditMemberPopup;
