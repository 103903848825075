import React, { useState, useEffect } from "react";
import TableNoResultFound from "../../constant/NoResultFound/TableNoResultFound.js";
import Pagination from "../../constant/Pagination.jsx";
import { Card, CardFooter, Table, Row } from "reactstrap";
import { getAllSalesReportList } from "../../services/ReportService.js";

const recordsPage = 20;

const AllSalesReport = ({ searchQuery, year, month }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [data, setData] = useState([]);  

    useEffect(() => {
        setIsLoading(true);
        getAllSalesReportList(currentPage, recordsPage, searchQuery, year, month)
          .then((resp) => {
            setData(resp.data.results);
            setTotal(resp.data.count);
            setIsLoading(false);
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data.message);
            } else {
              console.log(error);
            }
            setIsLoading(false);
          });
      }, [currentPage, searchQuery, year, month]);

    const handlePagination = (page) => {
        const new_page = page.selected + 1;
        setCurrentPage(new_page);
    };

    return (
        <div className="px-0" id="table-to-download">
            <Card className="shadow">
                <Table className="table-component__table align-items-center table-flush" responsive>
                    <thead className="booking-table-head disable-select">
                        <tr className="booking-table-header">
                            <th scope="col">Payment Source</th>
                            <th scope="col">Total Sales</th>
                            <th scope="col">Total GST</th>
                            <th scope="col">Total Discount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading ? (
                            <tr>
                                <td className="text-center my-7" colSpan="4">
                                    <div style={{ width: "100%", height: "100%" }}>
                                        <div className="spinner-grow text-black me-2" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ) : data.payment_source_sales?.length > 0 ? (
                            data.payment_source_sales.map((item, index) => (
                                <tr key={index} className="booking-table-row">
                                    <td>{item?.payment_source}</td>
                                    <td>{item?.total_sales}</td>
                                    <td>{item?.total_gst}</td>
                                    <td>{item?.total_discount}</td>
                                </tr>
                            ))
                        ) : (
                            <TableNoResultFound />
                        )}
                    </tbody>
                </Table>

                <CardFooter className="py-4">
                    <Pagination
                        currentPage={currentPage}
                        recordsPage={recordsPage}
                        dataLength={total}
                        handlePagination={handlePagination}
                    />
                </CardFooter>
            </Card>
        </div>
    );
};

export default AllSalesReport;
