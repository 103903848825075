import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { toast } from "react-toastify";
import {
  verifyMemberAPI,
  generateMembershipIdAPI,
  updateMembershipIdAPI,
  getAvailableMembershipIdsAPI
} from "../../services/VerifyMemberService";
import { FaForward } from "react-icons/fa";
import "./memberCustom.css";

const INPUT_OPTION_LIST = [
  { label: "SAIL RSP Member", value: "SAIL RSP Member" },
  { label: "GOVERNMENT Member", value: "GOVERNMENT Member" },
  { label: "PRIVATE Member", value: "PRIVATE Member" },
  { label: "Honorary Member", value: "Honorary Member" },
];

const VerifyMemberPopup = ({ member, data, setData }) => {
  const [showForm, setShowForm] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [membershipType, setMembershipType] = useState(member.membership_type || "SAIL RSP Member");
  const [customMembershipId, setCustomMembershipId] = useState(member.membership_id || ""); // Editable input
  const [availableMembershipIds, setAvailableMembershipIds] = useState([]);

  const toggleForm = () => {
    setShowForm((prev) => !prev);

    if (!showForm) {
      setMembershipType(member.membership_type || "SAIL RSP Member");
      setCustomMembershipId(member.membership_id || "");

      const membershipTypePayloadMap = {
        "SAIL RSP Member": "RSP",
        "GOVERNMENT Member": "RSP",
        "PRIVATE Member": "RSP",
        "Honorary Member": "HON",
      };
      const mappedMembershipType = membershipTypePayloadMap[membershipType] || membershipType;

      generateMembershipIdAPI(mappedMembershipType)
        .then((resp) => {
          setCustomMembershipId(resp.data.results);
        })
        .catch((error) => {
          toast.error("Failed to generate membership ID");
        });

      getAvailableMembershipIdsAPI()
        .then((response) => {
          if (response.data && Array.isArray(response.data.results)) {
            setAvailableMembershipIds(response.data.results);
          } else {
            throw new Error("Unexpected data structure for available IDs");
          }
        })
        .catch((error) => {
          toast.error("Failed to fetch available membership IDs");
        });
    }
  };

  const handleMembershipTypeChange = (e) => {
    setMembershipType(e.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isSubmitLoading) return;

    setIsSubmitLoading(true);
    const formData = {
      membership_type: membershipType,
      member_id: member.id,
      membership_id: customMembershipId,
    };

    verifyMemberAPI(formData)
      .then((resp) => {
        const { results, message } = resp.data;
        const updatedData = data.map((d) => (d.id === results.id ? results : d));
        setData(updatedData);
        toast.success(message);
        toggleForm();
      })
      .catch((error) => {
        toast.error(error.response ? error.response.data.message : "Verification failed");
      })
      .finally(() => {
        setIsSubmitLoading(false);
      });
  };

  const handleSkipClick = () => {
    updateMembershipIdAPI(customMembershipId)
      .then(() => {
        toast.success("Membership ID skipped and updated successfully!");
        const membershipTypePayloadMap = {
          "SAIL RSP Member": "RSP",
          "GOVERNMENT Member": "RSP",
          "PRIVATE Member": "RSP",
          "Honorary Member": "HON",
        };
        const mappedMembershipType = membershipTypePayloadMap[membershipType] || membershipType;

        return generateMembershipIdAPI(mappedMembershipType);
      })
      .then((resp) => {
        setCustomMembershipId(resp.data.results);
        return getAvailableMembershipIdsAPI();
      })
      .then((response) => {
        if (response.data && Array.isArray(response.data.results)) {
          setAvailableMembershipIds(response.data.results);
        }
      })
      .catch((error) => {
        toast.error("Failed to update membership ID");
      });
  };

  // Function to filter available membership IDs based on the membership type
  const filteredMembershipIds = availableMembershipIds.filter((id) => {
    if (membershipType === "Honorary Member") {
      return id.membership_id.startsWith("HON"); // Show only IDs with "HON" prefix
    }
    return !id.membership_id.startsWith("HON"); // Show other IDs
  });

  return (
    <div>
      {member.is_verified ? (
        <Button className="btn-sm" style={{ color: "white", backgroundColor: "green", border: "none" }}>
          VERIFIED
        </Button>
      ) : (
        <Button className="btn-sm" onClick={toggleForm} style={{ color: "white", backgroundColor: "grey", border: "none" }}>
          PENDING
        </Button>
      )}

      {showForm && (
        <div className="custom-popup">
          <form className="custom-popup-inner" onSubmit={handleSubmit}>
            <div className="custom-popup-header">
              <h2 className="text-white">Verify Member</h2>
            </div>
            <div className="custom-popup-body">
              <div className="custom-form-group">
                <label htmlFor="membershipType">Membership Type:</label>
                <select id="membershipType" name="membershipType" value={membershipType} onChange={handleMembershipTypeChange} className="custom-select">
                  {INPUT_OPTION_LIST.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>

              <div className="custom-form-group">
                <label htmlFor="membershipId">Membership ID:</label>
                <div className="input-group">
                  <input type="text" id="membershipId" value={customMembershipId} className="custom-form-control" readOnly />
                  <div className="input-group-append">
                    <Button className="btn-sm btn-outline-secondary" onClick={handleSkipClick}>
                      <FaForward />
                    </Button>
                  </div>
                </div>
              </div>

              <div className="custom-form-group">
                <label htmlFor="availableMembershipIds">Available IDs:</label>
                <select
                  id="availableMembershipIds"
                  className="custom-select custom-select-fixed"
                  value={customMembershipId}
                  onChange={(e) => setCustomMembershipId(e.target.value)}
                >
                  {filteredMembershipIds.length > 0 ? (
                    filteredMembershipIds.map((id, index) => (
                      <option key={index} value={id.membership_id}>
                        {id.membership_id}
                      </option>
                    ))
                  ) : (
                    <option disabled>No available IDs</option>
                  )}
                </select>
              </div>

            </div>

            <div className="btn-container">
              <button type="submit" className="submit-btn" disabled={isSubmitLoading}>
                {isSubmitLoading ? "Verifying..." : "Submit"}
              </button>
              <button type="button" className="cancel-btn" onClick={toggleForm}>
                Cancel
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default VerifyMemberPopup;
