import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Pagination from "../../../../constant/Pagination";
import { connect } from "react-redux";
import useWindowSize from "../../../../constant/WindowSize";
import { getOpenRoomList } from "../../../../services/RoomService";
import CalendarPopup from "../PopUpPage/CalendarPopUp";
import Loader from "../../../Loader/Loader";
import RoomTypeDropDown, { SortTypeDropDown } from "../../../../views/BookingStatus/DropDown";
import { Carousel } from "react-responsive-carousel";

const AllRooms = ({ user }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [filter, setFilter] = useState({
    room_type: "",
  });
  const [sortBy, setSortBy] = useState("-created_at");
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const recordsPage = 24;

  const size = useWindowSize();
  const isMobile = size.width <= 1024;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    getOpenRoomList(currentPage, recordsPage, searchQuery, filter.room_type, sortBy)
      .then((resp) => {
        setData(resp.data.results);
        setTotal(resp.data.count);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.log(error);
        }
        setIsLoading(false);
      });
  }, [currentPage, searchQuery, filter.room_type, sortBy]);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchQuery(value);
  };

  const handleFilterChange = (name, value) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
    setCurrentPage(1);
  };



  const handlePagination = (page) => {
    const new_page = page.selected + 1;
    setCurrentPage(new_page);
  };

  const handleShowDetails = (room) => {
    navigate(`/room-details/${room.id}`, { state: { room } });
  };

  const handleBookNow = (room) => {
    if (room?.id) {
      navigate(`/booking/${room.id}`, { state: { room } });
    } else {
      console.error("Room ID is missing");
    }
  };

  return (
    <>
        <div
        className="dark:bg-gray-900 dark:text-white bg-gray-50 py-1"
        style={{ minHeight: "100vh" }}
      >
        <section data-aos="fade-up" className="container">
          <div className="d-flex align-items-center justify-content-between flex-wrap">
            <h1 className="mb-2 mt-3 border-l-8 border-primary/50 py-2 pl-2 text-3xl font-bold">
              Rooms
            </h1>
   
            <div className="row align-items-center mb-2">
              <div className="col-md-5 mb-sm-0 mb-2">
                <div className="search-bar  d-flex align-items-center">
                  <input
                    type="text"
                    className="form-control search-input w-100"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                  <i className="fas fa-search search-icon ms-2"></i>
                </div>
              </div>

              <div className="col-md-7 d-flex justify-content-between align-items-center gap-3">
                <RoomTypeDropDown
                  setFilter={setFilter}
                  filter={filter}
                  setCurrentPage={setCurrentPage}
                  style={{
                    color: "#6c757db5",
                    backgroundColor: "white",
                    border: "1px solid #ccd3d8",
                  }}
                  classNamedropdown="w-100"
                  className={`${isMobile ? "w-100" : ""}`}
                />

                <SortTypeDropDown
                  setSortBy={setSortBy}
                  sortBy={sortBy}
                  style={{
                    color: "#6c757db5",
                    backgroundColor: "white",
                    border: "1px solid #ccd3d8",
                  }}
                  classNamedropdown="w-100"
                  className={`${isMobile ? "w-100" : ""}`}
                />
              </div>
            </div>

          </div>
          <>
            {isLoading ? (
              <div
                className="text-center my-7"
                style={{ width: "100%", height: "100%" }}
              >
                <Loader />
              </div>
            ) : data.length > 0 ? (
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                {data.map((item, index) => (
                  <div

                    key={index}
                    className="shadow-lg transition-all duration-500 hover:shadow-xl bg-slate-200 dark:text-white cursor-pointer rounded-2xl overflow-hidden"
                  >
                    <div className="overflow-hidden">
                      <div style={{ height: "285px" }} onClick={() => handleShowDetails(item)}>
                        <Carousel
                          autoPlay={false}
                          interval={3000}
                          infiniteLoop={true}
                          showIndicators={true}
                          showThumbs={false}
                          swipeable={true}
                          renderArrowPrev={(clickHandler, hasPrev, label) =>
                            hasPrev && (
                              <button
                                type="button"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  clickHandler();
                                }}
                                aria-label={label}
                                className="absolute top-1/2 left-3 z-10 transform -translate-y-1/2 bg-black/60 text-white rounded-full p-2"
                                style={{ zIndex: 10 }}
                              >
                                ❮
                              </button>
                            )
                          }
                          renderArrowNext={(clickHandler, hasNext, label) =>
                            hasNext && (
                              <button
                                type="button"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  clickHandler();
                                }}
                                aria-label={label}
                                className="absolute top-1/2 right-3 z-10 transform -translate-y-1/2 bg-black/60 text-white rounded-full p-2"
                                style={{ zIndex: 10 }}
                              >
                                ❯
                              </button>
                            )
                          }
                        >
                          {item.roomphoto.map((photo, index) => (
                            <div key={index}>
                              <img
                                src={`${process.env.REACT_APP_BASE_URL}${photo.photo}`}
                                alt={item.name}
                                className="img-fluid"
                                style={{
                                  height: "300px",
                                  width: "100%",
                                  objectFit: "cover",
                                  filter: "brightness(90%)",
                                }}
                              />
                            </div>
                          ))}
                        </Carousel>
                      </div>

                      <p
                        className="absolute my--6 bg-yellow w-52 text-center py-2 text-base text-black font-bold"
                        style={{ borderRadius: "0px 10px 10px 0px" }}
                      >
                        Rs.{item.price}
                      </p>
                    </div>

                    <div className="space-y-2 p-3">
                      <h1 className="line-clamp-1 font-bold text-lg">
                        {item.name}
                      </h1>
                      <div className="d-flex mb-3">
                        <small className="border-end me-3 pe-3">
                          {item.room_slot_type}
                        </small>
                        <small className="border-end me-3 pe-3">
                          {item.room_type}
                        </small>
                      </div>
                      <p className="line-clamp-1 text-gray-600">
                        {item.description}
                      </p>
                      <div
                        style={{ display: "flex", alignItems: "center" }}
                        className="flex items-center justify-between border-t-2 py-3 !mt-3"
                      >
                        <CalendarPopup
                          roomId={item.id}
                          roomSlotType={item.room_slot_type}
                          room={item}
                        />
                        {user ? (
                          <div>
                            <button
                              className="text-white bg-black px-3 py-1 rounded duration-200"
                              onClick={() => handleBookNow(item)}
                            >
                              Book Now
                            </button>
                          </div>
                        ) : (
                          <div>
                            <button
                              className="text-white bg-black px-3 py-1 rounded duration-200"
                              onClick={() =>
                                navigate(
                                  `/login?next_url=${`/room-details/${item?.id}`}`
                                )
                              }
                            >
                              Book Now
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="no-results-found text-center my-7">
                <p>No results found</p>
                <i className="fas fa-exclamation-circle text-5xl text-gray-400 mt-4"></i>
              </div>
            )}
          </>

          <Pagination
            currentPage={currentPage}
            recordsPage={recordsPage}
            dataLength={total}
            handlePagination={handlePagination}
          />
        </section>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(AllRooms);
