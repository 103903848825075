import React, { useState, useEffect } from "react";
import {
  Card,
  CardFooter,
  Table,
  Container,
  Row,
} from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import { format } from "date-fns";
import Header from "../../components/Headers/Header.js";
import Pagination from "../../constant/Pagination";
import { getBookingList } from "../../services/RoomService.js";
import { BsListColumns } from "react-icons/bs";
import useWindowSize from "../../constant/WindowSize";
import { Modal, Button } from "react-bootstrap";
import { LuArrowUpDown } from "react-icons/lu";
import { HiArrowNarrowDown, HiArrowNarrowUp } from "react-icons/hi";
import Loader from "../../components/Loader/Loader.js";
import TableNoResultFound from "../../constant/NoResultFound/TableNoResultFound.js";
import RoomTypeDropDown from "../BookingStatus/DropDown.js";
import DownloadExcel from "../BookingStatus/DownloadExcel.js";
const recordsPage = 15;

const BookingList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState({
    room_type: "",
    start_date: "",
    end_date: "",
  });
  const [hover, setHover] = useState(null);
  const [sortBy, setSortBy] = useState("-booking_slot__booking_date");
  const [expandedRows, setExpandedRows] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const size = useWindowSize();
  const isMobile = size.width <= 1024;
  const initialVisibleHeaders = [
    "Booking No.",
    "Member Name",
    "Room Name",
    "Amount",
  ];
  const headers = [
    {
      label: "Booking No.",
      accessor: (item) => item?.booking_number,
      sortKey: "booking_number",
      width: 15,
    },
    {
      label: "Member Name",
      accessor: (item) => `${item?.user?.first_name} ${item?.user?.last_name}`,
      sortKey: "user__first_name",
      width: 25,
    },
    {
      label: "Room Name",
      accessor: (item) => item?.booking_slot?.room?.name,
      sortKey: "booking_slot__room__name",
      width: 20,
    },
    {
      label: "Booking Date",
      accessor: (item) => item?.booking_slot?.booking_date,
      sortKey: "booking_slot__booking_date",
      width: 20,
    },
    {
      label: "Booking Time",
      accessor: (item) => item?.booking_slot?.booking_time,
      sortKey: "booking_slot__booking_time",
      width: 20,
    },
    {
      label: "Amount",
      accessor: (item) => item?.booking_net_amount,
      sortKey: "booking_net_amount",
      width: 15,
    },
    {
      label: "Date of Booking",
      accessor: (item) => item?.created_at,
      sortKey: "created_at",
      width: 20,
    },
  ];
  const initialColumns = headers.filter((header) =>
    initialVisibleHeaders.includes(header.label)
  );
  const additionalColumns = headers.filter(
    (header) => !initialVisibleHeaders.includes(header.label)
  );
  const [visibleHeaders, setVisibleHeaders] = useState(initialColumns);
  const [selectedColumns, setSelectedColumns] = useState(
    () => new Set(initialColumns.map((column) => column.label))
  );
  const [columnOrder, setColumnOrder] = useState(
    visibleHeaders.map((header) => header.sortKey)
  );

  const [selectAll, setSelectAll] = useState(false);

  const handleColumnSelection = (label) => {
    const updatedColumns = new Set(selectedColumns);

    if (updatedColumns.has(label)) {
      updatedColumns.delete(label);
    } else {
      updatedColumns.add(label);
    }

    setSelectedColumns(updatedColumns);
    setSelectAll(updatedColumns.size === headers.length);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedColumns(new Set());
    } else {
      setSelectedColumns(new Set(headers.map((header) => header.label)));
    }
    setSelectAll(!selectAll);
  };

  const handleApplyColumns = () => {
    const updatedVisibleHeaders = headers.filter((header) =>
      selectedColumns.has(header.label)
    );
    setVisibleHeaders(updatedVisibleHeaders);
    setColumnOrder(updatedVisibleHeaders.map((header) => header.sortKey));
    setShowModal(false);
  };

  const renderColumnOptions = (columns) => {
    return columns.map((header, index) => (
      <div
        key={index}
        className="table-component__column-option"
        onClick={() => handleColumnSelection(header.label)}
        style={{ userSelect: "none" }}
      >
        <label className="table-component__checkbox-label">
          <input
            type="checkbox"
            className="table-component__checkbox"
            checked={selectedColumns.has(header.label)}
            onChange={() => handleColumnSelection(header.label)}
          />
          {header.label}
        </label>
        {selectedColumns.has(header.label) && (
          <span
            className="cancel-icon"
            onClick={(e) => {
              e.stopPropagation();
              handleColumnSelection(header.label);
            }}
          >
            ×
          </span>
        )}
      </div>
    ));
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("text/plain", index);
  };

  const handleDrop = (e, index) => {
    e.preventDefault();
    const fromIndex = e.dataTransfer.getData("text/plain");
    const newOrder = [...columnOrder];
    const [movedItem] = newOrder.splice(fromIndex, 1);
    newOrder.splice(index, 0, movedItem);
    setColumnOrder(newOrder);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleRowExpandToggle = (index) => {
    let newExpandedRows = [...expandedRows];
    if (newExpandedRows.includes(index)) {
      newExpandedRows = newExpandedRows.filter(
        (rowIndex) => rowIndex !== index
      );
    } else {
      newExpandedRows.push(index);
    }
    setExpandedRows(newExpandedRows);
  };

  useEffect(() => {
    setIsLoading(true);
    getBookingList(
      currentPage,
      recordsPage,
      searchQuery,
      filter.room_type,
      filter.start_date,
      filter.end_date,
      sortBy
    )
      .then((resp) => {
        setData(resp.data.results);
        setTotal(resp.data.count);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.log(error);
        }
        setIsLoading(false);
      });
  }, [
    currentPage,
    searchQuery,
    filter.room_type,
    filter.start_date,
    filter.end_date,
    sortBy,
  ]);

  const handlePagination = (page) => {
    const new_page = page.selected + 1;
    setCurrentPage(new_page);
  };

  const handleResetDateFilter = () => {
    setFilter({
      ...filter,
      start_date: "",
      end_date: "",
    });
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchQuery(value);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleShowDetails = (room) => {
    handleShow();
    setSelectedRoom(room);
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setFilter({
      ...filter,
      start_date: start ? format(start, "dd-MM-yyyy") : "",
      end_date: end ? format(end, "dd-MM-yyyy") : "",
    });
  };

  const buttonStyle = {
    color: "white",
    backgroundColor: "rgb(19 19 ,19)",
    border: "none",
    transition: "background-color 0.3s ease, color 0.3s ease",
  };

  const hoverStyle = {
    backgroundColor: "#f8f9fa",
    color: "black",
  };

  const handleSort = (key) => {
    const sorbtbysplit = sortBy.split("-");
    const isAsc = sorbtbysplit.length > 1;
    const sortByValue = isAsc ? sorbtbysplit[1] : sortBy;
    if (sortByValue === key) {
      setSortBy(isAsc ? key : `-${key}`);
    } else {
      setSortBy(key);
    }
  };
  const renderSortIcon = (key) => {
    const sorbtbysplit = sortBy.split("-");
    const isAsc = sorbtbysplit.length > 1;
    const sortByValue = isAsc ? sorbtbysplit[1] : sortBy;
    if (sortByValue === key) {
      return isAsc ? <HiArrowNarrowUp /> : <HiArrowNarrowDown />;
    }
    return (
      <>
        <LuArrowUpDown />
      </>
    );
  };

  return (
    <>
      <Header />
      <Container
        className="Container mt-sm-0 mt-7 mb-sm-0 mb-7 p-sm-2 p-1"
        fluid
      >
        <div className="d-flex flex-wrap align-items-center justify-content-between p-2 ">
          <div className="d-flex align-items-center justify-content-between">
            <h3 className="mb-2 text-dark fw-bold pb-2 me-3">
              <i className="fas fa-history me-2 "></i>
              Booking History
            </h3>

          </div>
          <div className={`row align-items-end ${isMobile ? "w-100" : "w-50"}`}>
            <div className="col-md-6 mb-3 position-relative">
              <Flatpickr
                value={[filter.start_date, filter.end_date]}
                onChange={handleDateChange}
                options={{ mode: "range", dateFormat: "d-m-Y" }}
                className="form-control custom-flatpickr w-100 p-2 mx-auto border border-gray-800 bg-white rounded-md shadow-sm focus:outline-none focus:ring-2"
                placeholder="Select date range"
                style={{ fontSize: "0.75rem" }}
              />
              {filter.start_date && filter.end_date && (
                <i
                  className="fas fa-times position-absolute"
                  style={{
                    right: "45px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                    color: "#999999",
                  }}
                  onClick={handleResetDateFilter}
                />
              )}
            </div>

            {/* Search Bar */}
            <div className="col-md-6 mb-3">
              <div className="search-bar">
                <input
                  type="text"
                  className="form-control search-input w-100"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
                <i className="fas fa-search search-icon"></i>
              </div>
            </div>
          </div>

          <style jsx>{`
            .custom-flatpickr {
              border: 1px solid #ced4da !important;
            }
            .custom-flatpickr:focus {
              border-color: black !important;
            }
            .sorting {
              font-size: 0.6rem;
            }
            .disable-select {
              user-select: none;
            }
          `}</style>
        </div>
        <div className="summary-section d-flex align-items-center justify-content-between p-2 mx-2">
          <div className="d-flex align-items-center">
            {(
              <>
                <RoomTypeDropDown setFilter={setFilter} filter={filter} setCurrentPage={setCurrentPage} />

              </>
            )}
          </div>
          {!isMobile && (
            <div className="me-3 ms-auto">
              <button
                className="btn btn-info"
                onClick={() => setShowModal(true)}
              >
                <BsListColumns style={{ fontSize: "1.4rem" }} />
              </button>
              <Modal
                className="table-component__modal"
                show={showModal}
                onHide={() => setShowModal(false)}
                size="lg"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Select Columns</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="table-component__column-options">
                    <div className="table-component__select-all">
                      <label className="table-component__checkbox-label">
                        <input
                          type="checkbox"
                          className="me-3"
                          checked={selectAll}
                          onChange={handleSelectAll}
                        />
                        Select All
                      </label>
                    </div>
                    {renderColumnOptions(initialColumns)}
                    {renderColumnOptions(additionalColumns)}
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    className="table-component__close-btn"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                  <button
                    className="table-component__apply-btn"
                    onClick={handleApplyColumns}
                  >
                    Apply
                  </button>
                </Modal.Footer>
              </Modal>
            </div>
          )}
          <div>
            <DownloadExcel
              currentPage={currentPage}
              recordPage={recordsPage}
              searchQuery={searchQuery}
              roomType={filter.room_type}
              startDate={filter.start_date}
              endDate={filter.end_date}
              isBookingList={true} // Set this to true for booking list functionality
            />


          </div>
        </div>
        <Row>
          <div className="col">
            <Card className="shadow">
              {isMobile ? (
                <>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light disable-select">
                      <tr style={{ fontSize: "1rem" }}>
                        <th
                          scope="col"
                          className="py-3"
                          onClick={() => handleSort("booking_number")}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            Booking No.
                            <span className="ms-1">
                              {renderSortIcon("booking_number")}
                            </span>
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="py-3"
                          onClick={() => handleSort("user__first_name")}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            Member Name
                            <span className="ms-1">
                              {renderSortIcon("user__first_name")}
                            </span>
                          </div>
                        </th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr>
                          <td colSpan="3" className="text-center my-7">
                            <Loader />
                          </td>
                        </tr>
                      ) : data ? (
                        data.map((item, index) => (
                          <React.Fragment key={index}>
                            <tr key={index}>
                              <td>{item.booking_number}</td>
                              <td>
                                {item.user?.first_name} {item.user?.last_name}
                                {item.is_on_behalf_booking && (
                                  <div
                                    style={{
                                      fontSize: "0.6rem",
                                      color: "gray",
                                    }}
                                  >
                                    (Booked By Super Admin)
                                  </div>
                                )}
                              </td>
                              <td>
                                <Button
                                  variant="outline-primary"
                                  size="sm"
                                  onClick={() => handleRowExpandToggle(index)}
                                >
                                  More...
                                </Button>
                              </td>
                            </tr>
                            {expandedRows.includes(index) && (
                              <tr>
                                <td colSpan="3">
                                  <div>
                                    <div className="d-flex align-items-center justify-content-between">
                                      <div
                                        style={{
                                          marginBottom: "10px",
                                          color: "#555",
                                        }}
                                      >
                                        <strong style={{ color: "#333" }}>
                                          Booking Date:{" "}
                                        </strong>
                                        {item.booking_slot?.booking_date}
                                      </div>
                                      <Button
                                        className="btn-sm"
                                        onClick={() => handleShowDetails(item)}
                                        style={
                                          hover === item.id
                                            ? { ...buttonStyle, ...hoverStyle }
                                            : buttonStyle
                                        }
                                        onMouseEnter={() => setHover(item.id)}
                                        onMouseLeave={() => setHover(null)}
                                      >
                                        Details
                                      </Button>{" "}
                                    </div>
                                    <div
                                      style={{
                                        marginBottom: "10px",
                                        color: "#555",
                                      }}
                                    >
                                      <strong style={{ color: "#333" }}>
                                        Room Name:{" "}
                                      </strong>
                                      {item.booking_slot?.room?.name}
                                    </div>

                                    <div
                                      style={{
                                        marginBottom: "10px",
                                        color: "#555",
                                      }}
                                    >
                                      <strong style={{ color: "#333" }}>
                                        Booking Time:{" "}
                                      </strong>
                                      {item.booking_slot?.booking_time}
                                    </div>
                                    <div
                                      style={{
                                        marginBottom: "10px",
                                        color: "#555",
                                      }}
                                    >
                                      <strong style={{ color: "#333" }}>
                                        Price:{" "}
                                      </strong>
                                      {(
                                        item.booking_slot?.room.price * 1.18
                                      ).toFixed(2)}
                                    </div>

                                    <div
                                      style={{
                                        marginBottom: "10px",
                                        color: "#555",
                                      }}
                                    >
                                      <strong style={{ color: "#333" }}>
                                        Date of Booking:{" "}
                                      </strong>
                                      {item?.created_at}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))
                      ) : (
                        <TableNoResultFound />
                      )}
                    </tbody>
                  </Table>
                  <CardFooter className="py-4">
                    <Pagination
                      currentPage={currentPage}
                      recordsPage={recordsPage}
                      dataLength={total}
                      handlePagination={handlePagination}
                    />
                  </CardFooter>
                </>
              ) : (
                <>
                  <Table
                    className="table-component__table align-items-center table-flush"
                    responsive
                  >
                    <thead className="thead-light disable-select">
                      <tr style={{ fontSize: "1rem" }}>
                        {columnOrder.map((sortKey, index) => {
                          const header = visibleHeaders.find(
                            (h) => h.sortKey === sortKey
                          );
                          return (
                            <th
                              key={index}
                              scope="col"
                              className="py-3"
                              onClick={() => handleSort(header.sortKey)}
                              draggable
                              onDragStart={(e) => handleDragStart(e, index)}
                              onDrop={(e) => handleDrop(e, index)}
                              onDragOver={handleDragOver}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {header.label}
                                <span className="ms-1">
                                  {renderSortIcon(header.sortKey)}
                                </span>
                              </div>
                            </th>
                          );
                        })}
                        <th scope="col">Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr>
                          <td
                            colSpan={columnOrder.length}
                            className="text-center my-7"
                          >
                            <div
                              className="text-center my-7"
                              style={{ width: "100%", height: "100%" }}
                            >
                              <div
                                className="spinner-grow text-black me-2"
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ) : data ? (
                        data.map((item, index) => (
                          <tr key={index}>
                            {columnOrder.map((sortKey, i) => {
                              const header = visibleHeaders.find(
                                (h) => h.sortKey === sortKey
                              );
                              return <td key={i}>{header.accessor(item)}</td>;
                            })}
                            <td>
                              <Button
                                className="btn-sm"
                                onClick={() => handleShowDetails(item)}
                              >
                                View Details
                              </Button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <TableNoResultFound />
                      )}
                    </tbody>
                  </Table>

                  <CardFooter className="py-4">
                    <Pagination
                      currentPage={currentPage}
                      recordsPage={recordsPage}
                      dataLength={total}
                      handlePagination={handlePagination}
                    />
                  </CardFooter>
                </>
              )}
            </Card>
          </div>
        </Row>
      </Container>
      <Modal show={show} onHide={handleClose} centered="true">
        <Modal.Header className="bg-black d-flex align-items-center justify-content-between py-1 px-3">
          <Modal.Title className="fs-5 text-white">Details</Modal.Title>
          <Button
            variant="close"
            className="btn-close hover:bg-transparent"
            aria-label="Close"
            onClick={handleClose}
          >
            <i className="fa-solid fa-xmark text-white"></i>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div className="card mt-3 p-3">
            {selectedRoom && (
              <>
                <h5
                  style={{
                    position: "relative",
                    top: "-20px",
                    right: "10px",
                  }}
                >
                  Booking Number: {selectedRoom.booking_number}
                </h5>
                <div className="d-flex align-items-center justify-content-between">
                  <ul>
                    <li style={{ fontSize: "0.8rem" }}>
                      <strong>Member Name : </strong>
                      <span>
                        {selectedRoom.user?.first_name}{" "}
                        {selectedRoom.user?.last_name}
                      </span>
                    </li>
                    <li style={{ fontSize: "0.8rem" }}>
                      <strong>Email Id : </strong>
                      <span>{selectedRoom.user?.email}</span>
                    </li>
                    <li style={{ fontSize: "0.8rem" }}>
                      {" "}
                      <strong>Phone Number : </strong>
                      <span>{selectedRoom.user?.phone_number}</span>
                    </li>
                  </ul>
                  <div>
                    <h4>
                      <strong>Room Name:</strong>{" "}
                      {selectedRoom.booking_slot?.room?.name}
                    </h4>
                    <span style={{ fontSize: "0.8rem" }}>
                      <i className="fa-regular fa-calendar-days me-2"></i>
                      Booking Date: {selectedRoom.booking_slot?.booking_date}
                    </span>
                    <br />
                    <span style={{ fontSize: "0.8rem" }}>
                      <i className="fa-solid fa-clock me-2"></i> Time:{" "}
                      {selectedRoom.booking_slot?.booking_time}
                    </span>
                    <p></p>
                  </div>
                </div>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BookingList;
