import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import { Table, Modal, Button } from "react-bootstrap"; // Import Modal and Button from react-bootstrap
import LoadingSkeleton from "../Member/LoadingSkeleton";
import useWindowSize from "../../constant/WindowSize";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { updateMaintenanceRoom, deleteRoom } from "../../services/RoomService";
import MemberDetailsPopup from "./MemberDetailsPopup";
import "./Room.css";

function Details({ handleEditClick, data, historyData, isLoading }) {
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [addToMaintenance, setAddToMaintenance] = useState(false);
  const [isReadMore, setIsReadMore] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const navigate = useNavigate();
  const size = useWindowSize();
  const isMobile = size.width <= 1024;

  const handleConfirmMaintenance = async (roomId, isMaintenance) => {
    try {
      setIsSubmitLoading(true);
      const maintenanceData = isMaintenance ? "YES" : "NO";
      const resp = await updateMaintenanceRoom(roomId, maintenanceData);
      toast.success(resp.data.message);
      setAddToMaintenance(false);
      navigate("/rooms");
    } catch (error) {
      toast.error(error.response.data.message);
    }
    finally {
      setIsSubmitLoading(false);
    }
  };

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const handleAvatarClick = (member) => {
    setSelectedMember(member);
    setIsPopupOpen(true);
  };

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleBookNow = (room) => {
    if (room?.id) {
      navigate(`/booking/${room.id}`, { state: { room } });
    } else {
      console.error("Room ID is missing");
    }
  };

  const handleDeleteClick = () => {
    setShowDeleteModal(true); 
  };

  const handleDeleteConfirm = async () => {
    setShowDeleteModal(false); 
    try {
      const response = await deleteRoom(data.id);
      toast.success(response.data.message);
      navigate("/rooms");
    } catch (error) {
      toast.error("Error deleting the room. Please try again.");
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mx-sm-3 mx-0 mb-3">
        <div className="d-flex align-items-center">
          <button
            type="button"
            className="btn btn-sm border-black text-black rounded-circle"
            onClick={() => navigate("/rooms")}
          >
            <i className="fa-solid fa-arrow-left"></i>
          </button>
          <h3 className="primary">Room Details</h3>
        </div>
        <div className="d-flex justify-content-end">
          <button
            className="btn bg-black text-white px-3 me-2"
            onClick={handleEditClick}
          >
            <i className="fas fa-pencil-alt me-2"></i> Edit
          </button>
          <button
            className="btn btn-danger px-3"
            onClick={handleDeleteClick}
          >
            <i className="fas fa-trash-alt me-2"></i> Delete
          </button>
        </div>
      </div>
      <div
        className="p-sm-4 px-1 py-2 border rounded shadow"
        style={{ backgroundColor: "#ffffff", marginBottom: "20px" }}
      >
        <div className="row">
          <div className="col-md-5">
            <div className="d-flex">
              <Carousel
                showArrows
                autoPlay={false}
                infiniteLoop
                interval={3000}
                transitionTime={1000}
                className="w-100"
                showThumbs={isMobile ? false : true}
              >
                {data.roomphoto.map((photo) => (
                  <div key={photo.id}>
                    <img
                      src={`${process.env.REACT_APP_BASE_URL}${photo.photo}`}
                      alt={data.name}
                      className="img-fluid"
                    />
                  </div>
                ))}
              </Carousel>
            </div>
            <div className="d-flex align-items-center justify-content-between mt-4">
              <h3
                style={{
                  color: "#333",
                  fontWeight: "bold",
                  marginBottom: "5px",
                }}
              >
                {data?.name}
              </h3>
              <button
                className="text-white bg-black px-3 py-1 rounded duration-200"
                onClick={() => handleBookNow(data)}
              >
                Book Now
              </button>
            </div>
            <div style={{ marginBottom: "10px", color: "#555" }}>
              <strong style={{ color: "#333" }}>Price: </strong>
              {data?.price}
            </div>
            <div>
              <strong style={{ color: "#333" }}>Description: </strong>
              {isReadMore
                ? data?.description
                : `${data?.description?.substring(0, 35)}...`}
              <span
                onClick={toggleReadMore}
                style={{
                  color: "blue",
                  cursor: "pointer",
                  marginLeft: "5px",
                }}
              >
                {isReadMore ? "Show Less" : "Read More"}
              </span>
            </div>

            <div className="mb-3">
              <small className="border-end me-3 pe-3" style={{ color: "#777" }}>
                <i className="fa-regular fa-clock"></i> {data?.room_slot_type}
              </small>
              <small className="border-end me-3 pe-3" style={{ color: "#777" }}>
                <i className="fa-solid fa-bed"></i> {data?.room_type}
              </small>
            </div>
          </div>
          <div className="col-md-7">
            <div className="form-check form-check-inline mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                name="maintenance"
                id="maintenance"
                value="Maintenance"
                checked={addToMaintenance}
                onChange={() => setAddToMaintenance(!addToMaintenance)}
              />
              <label className="form-check-label" htmlFor="maintenance">
                Add To Maintenance
              </label>
              {addToMaintenance && (
                <button
                  type="submit"
                  className="btn btn-sm btn-success ms-2"
                  disabled={isSubmitLoading}
                  onClick={() => handleConfirmMaintenance(data.id, true)}
                >
                  {isSubmitLoading ? (
                    <span>
                      <Spinner animation="border" size="sm" /> Confirming...
                    </span>
                  ) : (
                    "Confirm"
                  )}
                </button>
              )}
            </div>
            {historyData && historyData.length > 0 ? (
              <>
                <h6 className="mb-2 text-center">Booking History</h6>
                <div className="history-table-wrapper">
                  <Table className="history-table align-items-center table-flush table-compact" responsive>
                    <thead className="history-table-head">
                      <tr className="history-table-header">
                        <th scope="col" className="py-1">Member</th>
                        <th scope="col" className="py-1">Name</th>
                        <th scope="col" className="py-1">Book ID</th>
                        <th scope="col" className="py-1">Booking Date</th>
                        <th scope="col" className="py-1">Booking Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <LoadingSkeleton />
                      ) : (
                        historyData.map((item, index) => (
                          <tr key={index}>
                            <td className="text-center" onClick={() => handleAvatarClick(item.user)}>
                              {item.user?.profile_photo ? (
                                <div className="avatar-group">
                                  <a className="avatar avatar-sm" href="#pablo" onClick={(e) => e.preventDefault()}>
                                    <img
                                      alt="..."
                                      className="rounded-circle"
                                      src={`${process.env.REACT_APP_BASE_URL}${item.user?.profile_photo}`}
                                    />
                                  </a>
                                </div>
                              ) : (
                                <span className="text-avatar cursor-pointer">
                                  {item.user?.first_name.slice(0, 1)}
                                </span>
                              )}
                            </td>
                            <td className="text-center">
                              {item.user?.first_name} {item.user?.last_name}
                            </td>
                            <td className="text-center">{item.booking_number}</td>
                            <td className="text-center">{item.booking_slot?.booking_date}</td>
                            <td className="text-center">{item.booking_slot?.booking_time}</td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                </div>
              </>
            ) : (
              <p className="text-center">No booking history available.</p>
            )}

          </div>
        </div>
      </div>

      {/* Modal for confirmation */}
      <Modal show={showDeleteModal} onHide={handleDeleteCancel}>
        <Modal.Header className="bg-black" closeButton>
          <Modal.Title className="text-white">Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this room? This action cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteCancel}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteConfirm}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Popup for member details */}
      {isPopupOpen && (
        <MemberDetailsPopup member={selectedMember} togglePopup={togglePopup} />
      )}
    </>
  );
}

export default connect(null, {})(Details);
