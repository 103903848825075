import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { checkAutoLoginAction } from "./store/actions/AuthActions";
import AOS from "aos";
import "aos/dist/aos.css";
import Loading from "./components/Loader/Loading";
import logo from "./assets/img/UserPage/about2.gif";
import Login from "./components/Login/Login";
import Register from "./components/Login/Register";
import ResetPassword from "./components/Login/ForgotPassword/ResetPassword";
import routes from "./routes.js";
import AdminNavbar from "./components/Navbars/AdminNavbar.js";
import Layout from "./components/Public&User/MainComponent/Layout";
import Home from "./components/Public&User/MainComponent/Home";
import UserEventDetails from "./components/Public&User/Component/Events/UserEventDetails.js";
import About from "./components/Public&User/MainComponent/About";
import PlacesRoute from "./components/Public&User/MainComponent/PlacesRoute";
import UserRoomDetails from "./components/Public&User/Component/Rooms/UserRoomDetails";
import UserProfile from "./components/Public&User/Component/Profile/Profile";
import FilterPage from "./components/Public&User/Component/FilterPage/FilterPage";
import MandapRooms from "./components/Public&User/MainComponent/Rooms";
import BookingHistory from "./components/Public&User/Component/BookingHistory/BookingHistory";
import Rooms from "./views/Rooms/Rooms";
import MemberListPage from "./views/Member";
import BookingList from "./views/examples/BookingList";
import BookingStatus from "./views/BookingStatus/BookingStatus";
import AddRoom from "./views/Rooms/AddRoom";
import Dashboard from "./views/Dashboard/index.js";
import Profile from "./components/Profile/Profile";
import Sidebar from "./components/Sidebar/Sidebar.js";
import RoomDetails from "./views/Rooms/EditRoom.js";
import BottomNavbar from "./components/Public&User/Component/BottomNavbar/BottomNavbar.js";
import useWindowSize from "./constant/WindowSize.jsx";
import Events from "./views/Events/Events.js";
import BookingPage from "./components/Public&User/Component/PopUpPage/BookingPage.jsx";
import Payments from "./views/PaymentPage/Payments.js";
import Settings from "./components/Settings/index.js";
import Refund from "./components/Policy/Refund.js";
import Terms from "./components/Policy/Terms.js";
import PrivacyPolicy from "./components/Policy/PrivacyPolicy.js";
import Reports from "./views/Reports/Reports.js";

const urlList = [
  { path: "/dashboard", component: Dashboard , accessRole: ["Admin", "SuperAdmin"] },
  { path: "/rooms", component: Rooms, accessRole: ["Admin", "SuperAdmin"] },
  { path: "/payments", component: Payments, accessRole: ["Admin", "SuperAdmin"] },
  { path: "/reports", component: Reports, accessRole: ["Admin", "SuperAdmin"] },
  { path: "/member", component: MemberListPage, accessRole: ["Admin", "SuperAdmin"] },
  { path: "/settings", component: Settings, accessRole: ["Admin", "SuperAdmin"] },
  { path: "/booking-list", component: BookingList, accessRole: ["Admin", "SuperAdmin"] },
  { path: "/booking-status", component: BookingStatus, accessRole: ["Admin", "SuperAdmin"] },
  { path: "/events", component: Events, accessRole: ["Admin", "SuperAdmin"] },
  { path: "/room/:id", component: RoomDetails, accessRole: ["Admin", "SuperAdmin"] },
  { path: "/add-room", component: AddRoom, accessRole: ["Admin", "SuperAdmin"] },
  { path: "/profile", component: Profile, accessRole: ["Admin", "SuperAdmin"] },
  { path: "/refund-policy", component: Refund, accessRole: ["Admin", "SuperAdmin", "User"] },
  { path: "/terms", component: Terms, accessRole: ["Admin", "SuperAdmin", "User"] },
  { path: "/privacy-policy", component: PrivacyPolicy, accessRole: ["Admin", "SuperAdmin", "User"] },
  { path: "/booking/:id", component: BookingPage, accessRole: ["Admin", "SuperAdmin", "User"] },
  { path: "/", component: Home, accessRole: ["User"] },
  { path: "/club-rooms", component: MandapRooms, accessRole: ["User"] },
  { path: "/room-details/:id", component: UserRoomDetails, accessRole: ["User"] },
  { path: "/user-profile", component: UserProfile, accessRole: ["User"] },
  { path: "/booking-history", component: BookingHistory, accessRole: ["User"] },
  { path: "/filter", component: FilterPage, accessRole: ["User"] },
  { path: "/about", component: About, accessRole: ["User"] },
  { path: "/events", component: PlacesRoute, accessRole: ["User"] },
  { path: "/event-details/:id", component: UserEventDetails , accessRole: ["User"] },
  { path: "/login", component: Login, accessRole: [null] },
  { path: "/signup", component: Register, accessRole: [null] },
  { path: "/reset-password/:id/:token", component: ResetPassword, accessRole: [null] },
];

const FlashScreen = () => {
  return (
    <div className="flash-screen">
      <div className="flash-body">
        <img src={logo} alt="" width={'50px'} style={{ marginRight: '0.6rem' }} />
      </div>
    </div>
  )
}

function AppContent() {
  const authState = useSelector((state) => state.auth);
  const { user: authUser, isCheckingAuth } = authState;
  const mainContent = React.useRef(null);
  const location = useLocation();

  useEffect(() => {
    AOS.init({
      offset: 100,
      duration: 700,
      easing: "ease-in-sine",
      delay: 10,
    });
    AOS.refresh();
  }, []);

  const size = useWindowSize();
  const isMobile = size.width <= 768;

  useEffect(() => {
    if (mainContent.current) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      mainContent.current.scrollTop = 0;
    }
  }, [location]);

  const getAdminRoutes = () => {
    return urlList
      .filter((prop) => ["Admin", "SuperAdmin"].includes(authUser.role) && prop.accessRole.includes(authUser.role))
      .map((prop, key) => (
        <Route key={key} path={prop.path} element={<prop.component />} exact />
      ));
  };

  const getUserRoutes = () => {
    return (
      <Route path="/" element={<Layout />}>
        {urlList
          .filter((prop) => prop.accessRole.includes("User"))
          .map((prop, key) => (
            <Route key={key} path={prop.path} element={<prop.component />} exact />
          ))}
      </Route>
    );
  };

  if (isCheckingAuth) {
    return <Loading />;
  }

  return (
    <>
      {authUser ? (
        (authUser.role === "Admin" || authUser.role === "SuperAdmin") ? (
          <>
            <Sidebar
              routes={routes}
              logo={{
                innerLink: "/dashboard",
                imgSrc: require("./assets/img/brand/logo.png"),
                imgAlt: "...",
              }}
            />
            <div className="main-content" ref={mainContent}>
              <AdminNavbar brandText={"Admin Dashboard"} />
              <Routes>
                {getAdminRoutes()}
                <Route path="*" element={<Navigate to="/dashboard" />} />
              </Routes>
            </div>
            {isMobile && (
              <BottomNavbar />
            )}
              {/* <Footer /> */}
          </>
        ) : (
          <Routes>
            {getUserRoutes()}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        )
      ) : (
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="club-rooms" element={<MandapRooms />} />
            <Route path="room-details/:id" element={<UserRoomDetails />} />
            <Route path="event-details/:id" element={<UserEventDetails />} />
            <Route path="filter" element={<FilterPage />} />
            <Route path="events" element={<PlacesRoute />} />
            <Route path="about" element={<About />} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Register />} />
          <Route
            path="/reset-password/:id/:token"
            element={!authUser ? <ResetPassword /> : <Navigate to="/" />}
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      )}
    </>
  );
}

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkAutoLoginAction());
  }, [dispatch]);

  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
