import React, { useState, useEffect } from "react";
import { Card, CardFooter, Table, Container, Row } from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import { format } from "date-fns";
import Header from "../../components/Headers/Header.js";
import Pagination from "../../constant/Pagination";
import { getPaymentList } from "../../services/RoomService.js";
import useWindowSize from "../../constant/WindowSize";
import { Button, Modal } from "react-bootstrap";
import { LuArrowUpDown } from "react-icons/lu";
import { HiArrowNarrowDown, HiArrowNarrowUp } from "react-icons/hi";
import { FcBullish } from "react-icons/fc";
import PaymentDetails from "./PaymentDetails.js";
import "./Payment.css";
import ExcelDownload from "../../utils/excelDownload.js";
import TableNoResultFound from "../../constant/NoResultFound/TableNoResultFound.js";
import { BsListColumns } from "react-icons/bs";
import DateRangeTypeDropDown, { StatusTypeDropDown } from "./Dropdown.js";

const recordsPage = 20;

const Payments = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [data, setData] = useState([]);
  const [dateRange, setDateRange] = useState("");
  const [filter, setFilter] = useState({
    status: "",
    start_date: "",
    end_date: "",
  });
  const [sortBy, setSortBy] = useState("-created_at");
  const [expandedRows, setExpandedRows] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState("");
  const size = useWindowSize();
  const isMobile = size.width <= 1024;
  const initialVisibleHeaders = [
    "Booking ID",
    "Transaction ID",
    "Member Name",
    "Amount",
  ];

  const headers = [
    {
      label: "Booking ID",
      accessor: (item) => item?.booking_transaction?.booking_number,
      sortKey: "booking_transaction__booking_number",
      width: 15,
    },
    {
      label: "Transaction ID",
      accessor: (item) =>
        item?.status === "SUCCESS" ? (
          <div>
            <span
              style={{ color: "green", fontSize: "1.5em", marginRight: "5px" }}
            >
              ✔
            </span>
            {item?.reference_number}
          </div>
        ) : (
          item?.reference_number
        ),
      sortKey: "reference_number",
      width: 30,
    },
    {
      label: "Date Of Booking",
      accessor: (item) => item?.created_at,
      sortKey: "created_at",
      width: 20,
    },
    {
      label: "Member Name",
      accessor: (item) => `${item?.user?.first_name} ${item?.user?.last_name}`,
      sortKey: "user__first_name",
      width: 25,
    },
    {
      label: "Booked Room",
      accessor: (item) => item?.booking_slot?.room?.name,
      sortKey: "booking_slot__room__name",
      width: 20,
    },
    {
      label: "Payment Source",
      accessor: (item) => item?.payment_source,
      sortKey: "payment_source",
      width: 20,
    },
    {
      label: "Amount",
      accessor: (item) => (
        <div
          style={{ cursor: "pointer" }}
          className="text-primary"
          onClick={() => handleAmountClick(item)}
        >
          {parseFloat(item?.net_amount).toFixed(2)}
        </div>
      ),
      sortKey: "net_amount",
      width: 15,
    },
  ];

  const initialColumns = headers.filter((header) =>
    initialVisibleHeaders.includes(header.label)
  );
  const additionalColumns = headers.filter(
    (header) => !initialVisibleHeaders.includes(header.label)
  );

  const [visibleHeaders, setVisibleHeaders] = useState(initialColumns);
  const [selectedColumns, setSelectedColumns] = useState(
    () => new Set(initialColumns.map((column) => column.label))
  );
  const [columnOrder, setColumnOrder] = useState(
    visibleHeaders.map((header) => header.sortKey)
  );
  const [showModal, setShowModal] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const handleColumnSelection = (label) => {
    const updatedColumns = new Set(selectedColumns);

    if (updatedColumns.has(label)) {
      updatedColumns.delete(label);
    } else {
      updatedColumns.add(label);
    }

    setSelectedColumns(updatedColumns);
    setSelectAll(updatedColumns.size === headers.length);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedColumns(new Set());
    } else {
      setSelectedColumns(new Set(headers.map((header) => header.label)));
    }
    setSelectAll(!selectAll);
  };

  const handleApplyColumns = () => {
    const updatedVisibleHeaders = headers.filter((header) =>
      selectedColumns.has(header.label)
    );
    setVisibleHeaders(updatedVisibleHeaders);
    setColumnOrder(updatedVisibleHeaders.map((header) => header.sortKey));
    setShowModal(false);
  };

  const renderColumnOptions = (columns) => {
    return columns.map((header, index) => (
      <div
        key={index}
        className="table-component__column-option"
        onClick={() => handleColumnSelection(header.label)}
        style={{ userSelect: "none" }} 
      >
        <label className="table-component__checkbox-label">
          <input
            type="checkbox"
            className="table-component__checkbox"
            checked={selectedColumns.has(header.label)}
            onChange={() => handleColumnSelection(header.label)}
          />
          {header.label}
        </label>
        {selectedColumns.has(header.label) && (
          <span
            className="cancel-icon"
            onClick={(e) => {
              e.stopPropagation(); // Prevents event bubbling to parent div
              handleColumnSelection(header.label);
            }}
          >
            ×
          </span>
        )}
      </div>
    ));
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("text/plain", index);
  };

  const handleDrop = (e, index) => {
    e.preventDefault();
    const fromIndex = e.dataTransfer.getData("text/plain");
    const newOrder = [...columnOrder];
    const [movedItem] = newOrder.splice(fromIndex, 1);
    newOrder.splice(index, 0, movedItem);
    setColumnOrder(newOrder);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleAmountClick = (item) => {
    setSelectedTransaction(item);
  };

  const handleRowExpandToggle = (index) => {
    let newExpandedRows = [...expandedRows];
    if (newExpandedRows.includes(index)) {
      newExpandedRows = newExpandedRows.filter(
        (rowIndex) => rowIndex !== index
      );
    } else {
      newExpandedRows.push(index);
    }
    setExpandedRows(newExpandedRows);
  };

  useEffect(() => {
    setIsLoading(true);
    getPaymentList(
      currentPage,
      recordsPage,
      searchQuery,
      selectedStatus,
      filter.start_date,
      filter.end_date,
      sortBy,
      dateRange
    )
      .then((resp) => {
        const { results, total_count_amount, count } = resp.data;
        const { SUCCESS, PENDING, FAILED } = total_count_amount.total_amount;
        let statusTotaAmount = 0;
        if (selectedStatus === "SUCCESS") {
          statusTotaAmount = SUCCESS;
        } else if (selectedStatus === "PENDING") {
          statusTotaAmount = PENDING;
        } else if (selectedStatus === "FAILED") {
          statusTotaAmount = FAILED;
        } else {
          statusTotaAmount = SUCCESS + PENDING + FAILED;
        }

        setData(results);
        setTotalAmount(statusTotaAmount);
        setTotal(count);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.log(error);
        }
        setIsLoading(false);
      });
  }, [
    currentPage,
    searchQuery,
    filter.start_date,
    filter.end_date,
    selectedStatus,
    sortBy,
    dateRange
  ]);

  const fetchFullData = async () => {
    try {
      const response = await getPaymentList(1, total, "", "", "", "", sortBy);
      return response.data.results;
    } catch (error) {
      console.error("Error fetching all data:", error);
      return [];
    }
  };

  const handlePagination = (page) => {
    const new_page = page.selected + 1;
    setCurrentPage(new_page);
  };

  const handleResetDateFilter = () => {
    setFilter({
      ...filter,
      start_date: "",
      end_date: "",
    });
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchQuery(value);
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setFilter({
      ...filter,
      start_date: start ? format(start, "yyyy-MM-dd") : "",
      end_date: end ? format(end, "yyyy-MM-dd") : "",
    });
  };

  const handleSort = (key) => {
    const sorbtbysplit = sortBy.split("-");
    const isAsc = sorbtbysplit.length > 1;
    const sortByValue = isAsc ? sorbtbysplit[1] : sortBy;
    if (sortByValue === key) {
      setSortBy(isAsc ? key : `-${key}`);
    } else {
      setSortBy(key);
    }
  };

  const renderSortIcon = (key) => {
    const sorbtbysplit = sortBy.split("-");
    const isAsc = sorbtbysplit.length > 1;
    const sortByValue = isAsc ? sorbtbysplit[1] : sortBy;
    if (sortByValue === key) {
      return isAsc ? <HiArrowNarrowUp /> : <HiArrowNarrowDown />;
    }
    return (
      <>
        <LuArrowUpDown />
      </>
    );
  };

  return (
    <>
      <Header />
      {selectedTransaction ? (
        <PaymentDetails
          transaction={selectedTransaction}
          onClose={() => setSelectedTransaction(null)}
        />
      ) : (
        <Container
          className="Container mt-sm-0 mt-7 mb-sm-0 mb-7 p-sm-2 p-1"
          fluid
        >
          <div className="d-flex flex-wrap align-items-center justify-content-between p-2">
            <h2 className="mb-2 text-dark fw-bold pb-2 ">
              <i className="fa-solid fa-sack-dollar p-1"></i>
              Payments
            </h2>
            <div
              className={`row align-items-end ${isMobile ? "w-100" : "w-50"}`}
            >
              <div className="col-md-6 mb-3">
                <Flatpickr
                  value={[filter.start_date, filter.end_date]}
                  onChange={handleDateChange}
                  options={{ mode: "range", dateFormat: "Y-m-d" }}
                  className="form-control custom-flatpickr w-full p-2 mx-auto border border-gray-800 bg-white rounded-md shadow-sm focus:outline-none focus:ring-2"
                  placeholder="Select date range"
                  style={{ fontSize: "0.75rem" }}
                />
                {filter.start_date && filter.end_date && (
                  <i
                    className="fas fa-times position-absolute"
                    style={{
                      right: "48px",
                      top: "52%",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                      color: "#999999",
                    }}
                    onClick={handleResetDateFilter}
                  />
                )}
              </div>
              <div className="col-md-6 mb-3">
                <div className="search-bar">
                  <input
                    type="text"
                    className="form-control search-input w-100"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                  <i className="fas fa-search search-icon"></i>
                </div>
              </div>
            </div>
          </div>
          <div className="summary-section d-flex flex-column flex-md-row align-items-center justify-content-between p-2">
            <div className="d-flex align-items-center mb-3 mb-md-0">
              <div className="summary-item me-6">
                <span className="summary-title">Total Payments</span>
                <span className="summary-amount">
                  ₹{totalAmount.toLocaleString()}{" "}
                  <FcBullish className="ms-1 icon-size" />
                </span>
              </div>
              <div className="summary-item">
                <span className="summary-title">Total Transactions</span>
                <span className="summary-count">
                  {total} <FcBullish className="ms-1 icon-size" />
                </span>
              </div>
            </div>

            <div className="d-flex align-items-center">
            <DateRangeTypeDropDown setDateRange={setDateRange} dateRange={dateRange} />
              <StatusTypeDropDown setSelectedStatus={setSelectedStatus} selectedStatus={selectedStatus} />
              {!isMobile && (
                <div className="me-3">
                  <button
                    className="btn btn-info"
                    onClick={() => setShowModal(true)}
                  >
                    <BsListColumns style={{ fontSize: "1.4rem" }} />
                  </button>
                  <Modal
                    className="table-component__modal"
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    size="lg"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Select Columns</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="table-component__column-options">
                        <div className="table-component__select-all">
                          <label className="table-component__checkbox-label">
                            <input
                              type="checkbox"
                              className="me-3"
                              checked={selectAll}
                              onChange={handleSelectAll}
                            />
                            Select All
                          </label>
                        </div>
                        {renderColumnOptions(initialColumns)}
                        {renderColumnOptions(additionalColumns)}
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <button
                        className="table-component__close-btn"
                        onClick={() => setShowModal(false)}
                      >
                        Close
                      </button>
                      <button
                        className="table-component__apply-btn"
                        onClick={handleApplyColumns}
                      >
                        Apply
                      </button>
                    </Modal.Footer>
                  </Modal>
                </div>
              )}

              <div className="ms-auto">
                <ExcelDownload
                  headers={headers}
                  data={data}
                  fileName="PaymentsData"
                  fetchFullData={fetchFullData}
                />
              </div>
            </div>
          </div>
          <Row>
            <div className="col" id="table-to-download">
              <Card className="shadow">
                {isMobile ? (
                  <>
                    <Table className="table-flush" responsive>
                      <thead className="thead-light disable-select">
                        <tr style={{ fontSize: "0.875rem" }}>
                          <th
                            scope="col"
                            className="py-2"
                            onClick={() => handleSort("reference_number")}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              Transaction ID
                              <span className="ms-1">
                                {renderSortIcon("reference_number")}
                              </span>
                            </div>
                          </th>
                          <th
                            scope="col"
                            className="py-2"
                            onClick={() => handleSort("net_amount")}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              Amount
                              <span className="ms-1">
                                {renderSortIcon("net_amount")}
                              </span>
                            </div>
                          </th>
                          <th scope="col" className="py-2">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <tr>
                            <div className="text-center my-5">
                              <div
                                className="spinner-border text-primary"
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            </div>
                          </tr>
                        ) : data ? (
                          data.map((item, index) => (
                            <React.Fragment key={index}>
                              <tr key={index}>
                                <td className="status-success">
                                  {item?.reference_number}
                                </td>
                                <td
                                  style={{
                                    marginBottom: "5px",
                                    cursor: "pointer",
                                    color: "#007bff",
                                  }}
                                  onClick={() => handleAmountClick(item)}
                                >
                                  {parseFloat(item.net_amount).toFixed(2)}
                                </td>
                                <td>
                                  <Button
                                    variant="outline-primary"
                                    size="sm"
                                    onClick={() => handleRowExpandToggle(index)}
                                  >
                                    More...
                                  </Button>
                                </td>
                              </tr>
                              {expandedRows.includes(index) && (
                                <tr>
                                  <td colSpan="3">
                                    <div style={{ fontSize: "0.75rem" }}>
                                      <div className="d-flex align-items-center justify-content-between">
                                        <div
                                          style={{
                                            marginBottom: "5px",
                                            color: "#555",
                                          }}
                                        >
                                          <strong style={{ color: "#333" }}>
                                            Booking No.:{" "}
                                          </strong>
                                          {
                                            item?.booking_transaction
                                              ?.booking_number
                                          }
                                        </div>
                                        <div
                                          style={{
                                            marginBottom: "5px",
                                            color: "#555",
                                          }}
                                        >
                                          <strong style={{ color: "#333" }}>
                                            Date of Booking:{" "}
                                          </strong>
                                          {item.created_at}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          marginBottom: "5px",
                                          color: "#555",
                                        }}
                                      >
                                        <strong style={{ color: "#333" }}>
                                          Username:{" "}
                                        </strong>
                                        {item?.user?.first_name}{" "}
                                        {item?.user?.last_name}
                                      </div>

                                      <div
                                        style={{
                                          marginBottom: "5px",
                                          color: "#555",
                                        }}
                                      >
                                        <strong style={{ color: "#333" }}>
                                          Room Name:{" "}
                                        </strong>
                                        {item?.booking_slot?.room?.name}
                                      </div>
                                      <div
                                        style={{
                                          marginBottom: "5px",
                                          color: "#555",
                                        }}
                                      >
                                        <strong style={{ color: "#333" }}>
                                          Payment Source:{" "}
                                        </strong>
                                        {item?.payment_source}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          ))
                        ) : <TableNoResultFound />}
                      </tbody>
                    </Table>

                    <CardFooter className="py-4">
                      <Pagination
                        currentPage={currentPage}
                        recordsPage={recordsPage}
                        dataLength={total}
                        handlePagination={handlePagination}
                      />
                    </CardFooter>
                  </>
                ) : (
                  <>
                    <Table
                      className="table-component__table align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light disable-select">
                        <tr style={{ fontSize: "1rem" }}>
                          {columnOrder.map((sortKey, index) => {
                            const header = visibleHeaders.find(
                              (h) => h.sortKey === sortKey
                            );
                            return (
                              <th
                                key={index}
                                scope="col"
                                className="py-3"
                                onClick={() => handleSort(header.sortKey)}
                                draggable
                                onDragStart={(e) => handleDragStart(e, index)}
                                onDrop={(e) => handleDrop(e, index)}
                                onDragOver={handleDragOver}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {header.label}
                                  <span className="ms-1">
                                    {renderSortIcon(header.sortKey)}
                                  </span>
                                </div>
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <tr>
                            <td
                              colSpan={columnOrder.length}
                              className="text-center my-7"
                            >
                              <div
                                className="text-center my-7"
                                style={{ width: "100%", height: "100%" }}
                              >
                                <div
                                  className="spinner-grow text-black me-2"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ) : data ? (
                          data.map((item, index) => (
                            <tr key={index}>
                              {columnOrder.map((sortKey, i) => {
                                const header = visibleHeaders.find(
                                  (h) => h.sortKey === sortKey
                                );
                                return <td key={i}>{header.accessor(item)}</td>;
                              })}
                            </tr>
                          ))
                        ) : <TableNoResultFound />}
                      </tbody>
                    </Table>

                    <CardFooter className="py-4">
                      <Pagination
                        currentPage={currentPage}
                        recordsPage={recordsPage}
                        dataLength={total}
                        handlePagination={handlePagination}
                      />
                    </CardFooter>
                  </>
                )}
              </Card>
            </div>
          </Row>
        </Container>
      )}
    </>
  );
};

export default Payments;
