import { useEffect, useRef, useState } from 'react';
import Flatpickr from 'react-flatpickr';
import "flatpickr/dist/themes/material_blue.css";

const RenderCalendar = ({
  handleDateChange,
  bookingDate,
  disableDateList,
  handleOnDayCreate,
}) => {
  const today = new Date();
  const maxDate = new Date();
  maxDate.setDate(today.getDate() + 90);

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    if (isCalendarOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isCalendarOpen]);

  return (
    <div style={{ position: 'relative' }}>
      <Flatpickr
        ref={inputRef} 
        value={bookingDate}
        onChange={handleDateChange}
        className="form-control custom-flatpickr w-100 p-2 border bg-transparent rounded shadow focus:outline-none focus:ring-2"
        placeholder="Select booking date"
        options={{
          dateFormat: 'Y-m-d',
          minDate: 'today',
          maxDate: maxDate,
          altInput: true,
          altFormat: 'd/m/Y',
          disableMobile: true,
          disable: disableDateList,
          onDayCreate: handleOnDayCreate,
          position: "below",
          onOpen: () => setIsCalendarOpen(true),
          onClose: () => setIsCalendarOpen(false),
        }}
      />

      {isCalendarOpen && (
        <div
          className="calendar-container"
          style={{
            position: 'absolute',  // Positioning the calendar absolutely
            zIndex: 999,
            top: '100%', // Position the calendar below the input field
            left: 0,
            visibility: isCalendarOpen ? 'visible' : 'hidden',
            width: 'max-content', // Ensure calendar width adapts to content
          }}
        />
      )}
    </div>
  );
};

export default RenderCalendar;
