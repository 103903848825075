import React from 'react'
import Header from '../Headers/Header'
import { Container } from "react-bootstrap";
import Coupon from './Coupon';
import BookingReasonPage from './BookingReason';
import CancelReasonPage from './CancelReason';

function Settings() {
  return (
    <>
    <Header />
      <Container className="mt-sm-0 mt-6 p-2" fluid>
        <div className='row'>
        <div className='col-md-6'>
         <BookingReasonPage />
        </div>
        <div className='col-md-6'>
          <CancelReasonPage />
        </div>
        </div>
      <Coupon />
      </Container>
      </>
  )
}

export default Settings