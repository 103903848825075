import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader, Nav, NavItem, NavLink, Row } from "reactstrap";
import { Bar } from "react-chartjs-2";
import { getMostRoomBookingGraph } from "../../services/DashBoardService";
import useWindowSize from "../../constant/WindowSize";
import classnames from "classnames";
import Loader from "../../components/Loader/Loader";
import "./Dashboard.css";

const RoomTypeGraph = () => {
  const [dataset, setDataSet] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [timePeriod, setTimePeriod] = useState("weekly");

  const size = useWindowSize();
  const isMobile = size.width <= 1024;

  const toggleTimePeriod = (tp) => {
    setTimePeriod(tp);
  };

  useEffect(() => {
    setIsLoading(true);
    getMostRoomBookingGraph(timePeriod)
      .then((resp) => {
        setDataSet(resp.data.results);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.log(error);
        }
        setIsLoading(false);
      });
  }, [timePeriod]);



  const wrapLabel = (label, maxLength) => {
    if (!label) return ""; // Check for null or undefined
    const words = label.split(" ");
    let wrapped = "";
    let currentLine = "";
    for (let word of words) {
      if (currentLine.length + word.length > maxLength) {
        wrapped += currentLine + "\n";
        currentLine = "";
      }
      currentLine += word + " ";
    }
    return wrapped + currentLine.trim();
  };

  const data = {
    labels: dataset.map((item) => wrapLabel(item?.room_name, 10)),
    datasets: [
      {
        label: "Bookings",
        data: dataset.map((item) => item?.booking_count),
        backgroundColor: "#6c5ce7",
        borderColor: "#00b894",
        borderWidth: 1,
        hoverBackgroundColor: "#d63031",
        hoverBorderColor: "#e17055",
      },
    ],
  };


  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      position: "top",
      labels: {
        fontColor: "#34495e",
        fontSize: 14,
        boxWidth: 20,
      },
    },
    tooltips: {
      backgroundColor: "#00b894",
      titleFontColor: "#fff",
      bodyFontColor: "#fff",
      bodyFontSize: 14,
      xPadding: 10,
      yPadding: 10,
      cornerRadius: 4,
      displayColors: false,
      callbacks: {
        label: function (tooltipItem) {
          const { xLabel, yLabel } = tooltipItem;
          return `Room: ${xLabel} - Bookings: ${yLabel}`;
        },
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            color: "#bdc3c7",
          },
          ticks: {
            fontColor: "#34495e",
            fontSize: 12,
            callback: function (value) {
              return value.split("\n");
            },
          },
          scaleLabel: {
            display: isMobile ? false : true,
            labelString: "Room Name",
            fontColor: "#34495e",
          },
          barThickness: isMobile ? 15 : 40,
          maxBarThickness: isMobile ? 15 : 40,
        },
      ],
      yAxes: [
        {
          gridLines: {
            color: "#bdc3c7",
          },
          ticks: {
            beginAtZero: true,
            fontColor: "#34495e",
            fontSize: 12,
          },
          scaleLabel: {
            display: isMobile ? false : true,
            labelString: "Number of Bookings",
            fontColor: "#34495e",
          },
        },
      ],
    },
    animation: {
      duration: 2000,
      easing: "easeOutBounce",
      onComplete: function () {
        const chartInstance = this.chart;
        const ctx = chartInstance.ctx;
        ctx.textAlign = "center";
        ctx.font = "bold 12px Arial";
        ctx.fillStyle = "#34495e";

        this.data.datasets.forEach((dataset, i) => {
          const meta = chartInstance.getDatasetMeta(i);
          meta.data.forEach((bar, index) => {
            const data = dataset.data[index];
            ctx.fillText(data, bar._model.x, bar._model.y - 5);
          });
        });
      },
    },
  };

  return (
    <Card className="shadow CardNEW" style={{ backgroundColor: "#ecf0f1", borderRadius: "20px", padding: "20px", position: "relative" }}>
      <CardHeader
        className="bg-white shadow-sm mb-3"
        style={{ borderBottom: "none", padding: "15px", borderRadius: "20px" }}
      >
        <Row className="align-items-center justify-content-center">
          <div className="col text-left">
            <h2 className="mb-0" style={{ color: "#2c3e50", textShadow: "1px 1px 3px rgba(0, 0, 0, 0.2)" }}>
              <strong>Most Booking Rooms</strong>
            </h2>
          </div>
          <div className="col">
            <Nav className="justify-content-end" pills>
              <NavItem>
                <NavLink
                  className={classnames("py-1 px-3", { active: timePeriod === "yearly" })}
                  style={{
                    backgroundColor: timePeriod === "yearly" ? "#d63031" : "transparent",
                    color: "#2c3e50",
                    borderRadius: "10px",
                    transition: "0.3s ease",
                  }}
                  onClick={() => toggleTimePeriod("yearly")}
                >
                  Y
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames("py-1 px-3", { active: timePeriod === "monthly" })}
                  style={{
                    backgroundColor: timePeriod === "monthly" ? "#6c5ce7" : "transparent",
                    color: "#2c3e50",
                    borderRadius: "10px",
                    transition: "0.3s ease",
                  }}
                  onClick={() => toggleTimePeriod("monthly")}
                >
                  M
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames("py-1 px-3", { active: timePeriod === "weekly" })}
                  style={{
                    backgroundColor: timePeriod === "weekly" ? "#00b894" : "transparent",
                    color: "#2c3e50",
                    borderRadius: "10px",
                    transition: "0.3s ease",
                  }}
                  onClick={() => toggleTimePeriod("weekly")}
                >
                  W
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </Row>
      </CardHeader>
      {isLoading ? (
        <div className="text-center my-7" style={{ width: "100%", height: "100%" }}>
          <Loader />
        </div>
      ) : (
        <CardBody
          className="p-sm-4 p-0"
          style={{
            position: "relative",
            height: isMobile ? "300px" : "400px",
            backgroundColor: "#ffffff",
            borderRadius: "15px",
            boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
            overflow: "hidden",
          }}
        >
          <div className="graph-background" />
          <Bar data={data} options={options} />
        </CardBody>
      )}
    </Card>
  );
};

export default RoomTypeGraph;
