import React from "react";
import html2pdf from "html2pdf.js";
import { FaDownload } from "react-icons/fa";
import useWindowSize from "../../constant/WindowSize";
import "./Payment.css";

const PaymentDetails = ({ transaction, onClose }) => {
  const size = useWindowSize();
  const isMobile = size.width <= 1024;

  if (!transaction) return null;

  const price =
    (transaction?.net_amount || 0) -
    (transaction?.gst_amount || 0) -
    (transaction?.discount_amount || 0);

  const downloadInvoice = () => {
    const element = document.getElementById("invoice");
    const buttons = document.querySelectorAll(".hide-when-printing");
    buttons.forEach((button) => (button.style.display = "none"));

    const options = {
      margin: 0.5,
      filename: "invoice.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    html2pdf()
      .from(element)
      .set(options)
      .toPdf()
      .get("pdf")
      .then((pdf) => {
        buttons.forEach((button) => (button.style.display = ""));
      })
      .save();
  };

  return (
    <div id="invoice" className="payment-card mx-sm-8 mx-1 mt-6 mt-sm-4 py-3">
      <div className="payment-header d-flex align-items-center justify-content-between mx-2 mx-sm-3 mb-3">
        <h3 className="transaction-id mb-3 me-2 me-sm-0">
          <strong>Transaction Id: </strong>
          <span>{transaction?.reference_number}</span>
        </h3>
        <div>
          {isMobile ? (
            <button
              className="download-btn-mobile btn btn-success text-center px-2"
              onClick={downloadInvoice}
            >
              <FaDownload />
            </button>
          ) : (
            <button
              className="download-btn-desktop btn btn-sm btn-success hide-when-printing"
              onClick={downloadInvoice}
            >
              Download Invoice
            </button>
          )}
        </div>
      </div>
      <hr className="divider px-0" />
      <div className="payment-details mx-0 mx-sm-3">
        <table className="details-table">
          <tbody>
            <tr>
              <td className="invoiced-to py-3">
                <p>
                  <strong>Invoiced To:</strong>
                  <p>
                    {transaction.user?.first_name} {transaction.user?.last_name}{" "}
                    <br />
                    {transaction.user?.email} <br />
                    HP12 3JL <br />
                    United Kingdom
                  </p>
                </p>
              </td>
              <td className="pay-to py-3">
                <p className="text-right">
                  <strong>Pay To:</strong>
                  <p>
                    Dummy Com <br />
                    2705 N. Enterprise St <br />
                    Orange, CA 92865 <br />
                    dum.my@dummy.com
                  </p>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr className="divider" />
      <div className=" mx-0 mx-sm-3">
        <table className="methods-table">
          <tbody>
            <tr>
              <td className="payment-method py-2">
                <p>
                  <strong>Payment Method :</strong>
                  <p>
                    <strong>Payment Source : </strong>
                    {transaction?.payment_source} <br />
                    Visa ending **** 4242
                  </p>
                </p>
              </td>
              <td className="order-date py-2">
                <p className="text-right">
                  <strong>Order Date:</strong>
                  <p>{transaction?.created_at}</p>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr className="divider" />
      <div className="payment-summary mx-3 mt-3 mb-sm-0 mb-8">
        <h2 className="summary-title mb-2">
          <strong>Payment Summary</strong>
        </h2>
        <table className="summary-table table table-hover">
          <thead>
            <tr>
              <th>Room Name</th>
              <th className="text-center">Price</th>
              <th className="text-center">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="room-name col-md-9">
                <em>{transaction.booking_slot?.room?.name}</em>
              </td>
              <td className="price col-md-1 text-center"> {transaction.booking_slot?.room?.price}</td>
              <td className="total col-md-1 text-center">{transaction.booking_slot?.room?.price}</td>
            </tr>
            <tr>
              <td></td>
              <td className="subtotal text-right">
                <p>
                  <strong>Subtotal: </strong>
                </p>
                <p>
                  <strong>GST: </strong>
                </p>
                {transaction?.discount_amount > 0 && (
                  <p>
                    <strong>Discount: </strong>
                  </p>
                )}
              </td>
              <td className="amounts text-center">
                <p>
                  <strong>{transaction.booking_slot?.room?.price}</strong>
                </p>
                <p>
                  <strong>{transaction?.gst_amount}</strong>
                </p>
                {transaction?.discount_amount > 0 && (
                  <p>
                    <strong>{transaction?.discount_amount}</strong>
                  </p>
                )}
              </td>
            </tr>
            <tr>
              <td>
                <button
                  className="cancel-btn btn btn-sm btn-danger px-3 hide-when-printing"
                  onClick={onClose}
                >
                  Cancel
                </button>
              </td>
              <td className="total-label text-right">
                <h4>
                  <strong>Total: </strong>
                </h4>
              </td>
              <td className="total-amount text-center text-danger">
                <h4>
                  <strong> {transaction?.net_amount} </strong>
                </h4>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PaymentDetails;
