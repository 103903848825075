import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getCurrentBookings } from "../../services/DashBoardService";
import "./Dashboard.css";

function HighlightData() {
  const [dataset, setDataSet] = useState({
    todays_bookings: {},
    tomorrows_bookings: {},
    pending_bookings: {},
    cancelled_bookings: {},
  });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    getCurrentBookings()
      .then((resp) => {
        setDataSet({
          todays_bookings: resp.data.todays_bookings,
          tomorrows_bookings: resp.data.tomorrows_bookings,
          pending_bookings: resp.data.pending_bookings,
          cancelled_bookings: resp.data.cancelled_bookings,
        });
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.log(error);
        }
        setIsLoading(false);
      });
  }, []);

  const getCount = (type) => {
    if (type === "today") {
      return dataset.todays_bookings.count || 0;
    } else if (type === "tomorrow") {
      return dataset.tomorrows_bookings.count || 0;
    } else if (type === "pending") {
      return dataset.pending_bookings.count || 0;
    } else if (type === "cancelled") {
      return dataset.cancelled_bookings.count || 0;
    }
    return 0;
  };

  const handleNavigate = (type) => {
    navigate("/booking-status", {
      state: { fetchBookings: true, bookingType: type },
    });
  };

  return (
    <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-6 w-full max-w-9xl mb-3">
      {/** Today's Bookings Card */}
      <div
        className="custom-card relative p-3 my-1 bg-gradient-to-r from-cyan-400 to-cyan-600 rounded-md overflow-hidden
        hover:cursor-pointer hover:scale-105 transition-transform duration-300"
        onClick={() => handleNavigate("today")}
      >
        <i className="fa-solid fa-calendar-day text-white text-3xl absolute top-3 left-3 animate-pulse"></i>
        <div className="flex flex-col items-center justify-center h-full">

          <div className="text-white text-4xl font-semibold">{getCount("today")}</div>


          <div className="text-white font-semibold">Today Bookings</div>
          <div className="flex items-center mt-2">
            <div className="text-xs text-white opacity-80 hover:underline">
              Click to view details
            </div>
            <i className="fa-solid  fa-arrow-right text-white text-2xl animate-slide-right transition-transform duration-300 hover:translate-x-1 ml-2 mt-1"></i>
          </div>

        </div>
      </div>

      {/** Tomorrow's Bookings Card */}
      <div
        className="custom-card relative p-3 my-1 bg-gradient-to-r from-teal-400 to-teal-500 rounded-md overflow-hidden
        hover:cursor-pointer hover:scale-105 transition-transform duration-300"
        onClick={() => handleNavigate("tomorrow")}
      >
        <i className="fa-solid fa-calendar-alt text-white text-3xl absolute top-3 left-3 animate-pulse"></i>
        <div className="flex flex-col items-center justify-center h-full">
          <div className="text-white text-4xl font-semibold">{getCount("tomorrow")}</div>
          <div className="text-white font-semibold">Tomorrow Bookings</div>
          <div className="flex items-center mt-2">
            <div className="text-xs text-white opacity-80 hover:underline">
              Click to view details
            </div>
            <i className="fa-solid  fa-arrow-right text-white text-2xl animate-slide-right transition-transform duration-300 hover:translate-x-1 ml-2 mt-1"></i>
          </div>
        </div>
      </div>

      {/** Pending Bookings Card */}
      <div
        className="custom-card relative p-3 my-1 bg-gradient-to-r from-blue-400 to-blue-600 rounded-md overflow-hidden
        hover:cursor-pointer hover:scale-105 transition-transform duration-300"
        onClick={() => handleNavigate("pending")}
      >
        <i className="fa-solid fa-clock text-white text-3xl absolute top-3 left-3 animate-pulse"></i>
        <div className="flex flex-col items-center justify-center h-full">
          <div className="text-white text-4xl font-semibold">{getCount("pending")}</div>
          <div className="text-white font-semibold">Pending Bookings</div>
          <div className="flex items-center mt-2">
            <div className="text-xs text-white opacity-80 hover:underline">
              Click to view details
            </div>
            <i className="fa-solid  fa-arrow-right text-white text-2xl animate-slide-right transition-transform duration-300 hover:translate-x-1 ml-2 mt-1"></i>
          </div>
        </div>
      </div>

      {/** Cancellations Card */}
      <div
        className="custom-card relative p-3 my-1 bg-gradient-to-r from-red-400 to-red-600 rounded-md overflow-hidden
        hover:cursor-pointer hover:scale-105 transition-transform duration-300"
        onClick={() => handleNavigate("cancelled")}
      >
        <i className="fa-solid fa-ban text-white text-3xl absolute top-3 left-3 animate-pulse"></i>
        <div className="flex flex-col items-center justify-center h-full">
          <div className="text-white text-4xl font-semibold">{getCount("cancelled")}</div>
          <div className="text-white font-semibold">Cancellations</div>
          <div className="flex items-center mt-2">
            <div className="text-xs text-white opacity-80 hover:underline">
              Click to view details
            </div>
            <i className="fa-solid  fa-arrow-right text-white text-2xl animate-slide-right transition-transform duration-300 hover:translate-x-1 ml-2 mt-1"></i>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HighlightData;
