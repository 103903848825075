import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import UserHeader from "../../../Headers/UserHeader";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getUserNameIcon } from "../../../../utils";
import { logoutAction } from "../../../../store/actions/AuthActions";
import useWindowSize from "../../../../constant/WindowSize";
import LogoutModal from "../../../Logout/Logout";
import ChangeProfilePhoto from "./ChangeProfilePhoto";
import ChangePassword from "./ChangePassword";
import "./UserProfile.css";


const UserProfile = ({ user }) => {
  const [isEditable, setIsEditable] = useState(false); // State to toggle edit mode
  const [exitModalOpen, setExitModalOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const size = useWindowSize();
  const isMobile = size.width <= 768;

  const toggleExitModal = () => setExitModalOpen(!exitModalOpen);

  function onLogout() {
    dispatch(logoutAction(navigate));
    toggleExitModal();
  }

  const handleEditToggle = () => {
    setIsEditable(!isEditable);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <UserHeader />
      <Container className="mt--8 profile-style px-sm-5 px-0" fluid>
        <Row className="profile-row">
          <Col className="order-xl-2 mb-3 mb-xl-0" xl="4">
            <Card className="card-profile shadow">
              <div className="profile-photo-container">
                {user?.profile_photo ? (
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}${user.profile_photo}`}
                    className="admin-profile-photo"
                    alt="profile"
                  />
                ) : (
                  <div className="lg-text-admin text-4xl d-flex align-items-center justify-content-center" >
                    {getUserNameIcon(user?.first_name + " " + user?.last_name)}
                  </div>
                )}
                <ChangeProfilePhoto />
              </div>
              <CardBody>
                <div className="text-center">
                  <h3>
                    {user?.first_name} {user?.last_name}
                  </h3>
                  <div className="h5 mt-4">
                    <i className="ni business_briefcase-24 mr-2" />
                    {user?.role} - Rourkela Club
                  </div>
                  <div>
                    <i className="ni education_hat mr-2" />
                    Rourkela
                  </div>
                  <hr className="my-4" />
                  <div className="mb-4 d-flex align-items-center justify-content-between">
                    <span className="text-sm text-mute">Want to Change Password? </span> <ChangePassword />
                  </div>
                  <span onClick={toggleExitModal} className="cursor-pointer">
                    <i className="fa-solid fa-power-off me-2"></i>
                    Logout
                  </span>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col className="order-xl-1 mb-7" xl="8">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">My account</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      onClick={handleEditToggle}
                      size="sm"
                    >
                      {isEditable ? "Save" : "Edit"}
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="profile-edit mb-4">
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    User information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6" className="mb-3">
                        <label
                          className="form-control-label d-flex align-items-start"
                          htmlFor="input-username"
                        >
                          Username
                        </label>
                        <Input
                          className="form-control-alternative"
                          defaultValue={`${user?.first_name || ""} ${user?.last_name || ""}`}
                          id="input-username"
                          placeholder="Username"
                          type="text"
                          readOnly={!isEditable} // Toggle readOnly
                        />
                      </Col>
                      <Col lg="6" className="mb-3">
                        <label
                          className="form-control-label d-flex align-items-start"
                          htmlFor="input-email"
                        >
                          Email
                        </label>
                        <Input
                          className="form-control-alternative"
                          defaultValue={user?.email}
                          id="input-email"
                          placeholder="Email"
                          type="email"
                          readOnly={!isEditable}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <label
                          className="form-control-label d-flex align-items-start"
                          htmlFor="input-first-name"
                        >
                          First name
                        </label>
                        <Input
                          className="form-control-alternative"
                          defaultValue={user?.first_name || "Test"}
                          id="input-first-name"
                          placeholder="First name"
                          type="text"
                          readOnly={!isEditable}
                        />
                      </Col>
                      <Col lg="6">
                        <label
                          className="form-control-label d-flex align-items-start"
                          htmlFor="input-last-name"
                        >
                          Last name
                        </label>
                        <Input
                          className="form-control-alternative"
                          defaultValue={user?.last_name || "User"}
                          id="input-last-name"
                          placeholder="Last name"
                          type="text"
                          readOnly={!isEditable} 
                        />
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  {/* Address */}
                  <h6 className="heading-small text-muted mb-4">
                    Contact information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col md="12" className="mb-3">
                        <label
                          className="form-control-label d-flex align-items-start"
                          htmlFor="input-address"
                        >
                          Address
                        </label>
                        <Input
                          className="form-control-alternative"
                          defaultValue={user?.address || "Bld Mihail Kogalniceanu, nr. 8 Bl 1, Sc 1, Ap 09"}
                          id="input-address"
                          placeholder="Home Address"
                          type="text"
                          readOnly={!isEditable} // Toggle readOnly
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <label
                          className="form-control-label d-flex align-items-start"
                          htmlFor="input-city"
                        >
                          City
                        </label>
                        <Input
                          className="form-control-alternative"
                          defaultValue={user?.city || "New York"}
                          id="input-city"
                          placeholder="City"
                          type="text"
                          readOnly={!isEditable} // Toggle readOnly
                        />
                      </Col>
                      <Col lg="6">
                        <label
                          className="form-control-label d-flex align-items-start"
                          htmlFor="input-postal-code"
                        >
                          Postal code
                        </label>
                        <Input
                          className="form-control-alternative"
                          defaultValue={user?.postal_code || ""}
                          id="input-postal-code"
                          placeholder="Postal code"
                          type="number"
                          readOnly={!isEditable} // Toggle readOnly
                        />
                      </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <LogoutModal
        show={exitModalOpen}
        onHide={toggleExitModal}
        onLogout={onLogout}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(UserProfile);


