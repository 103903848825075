import React, { useState, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { deleteEvent, updateEvent } from "../../services/EventService";
import { FaTrash } from "react-icons/fa";
import "./Events.css";

const EventDetails = ({ item, data, setData }) => {
    const [modalState, setModalState] = useState({ type: "View", open: false });
    const [isEditing, setIsEditing] = useState(false);
    const [eventInput, setEventInput] = useState({
        id: "",
        title: "",
        description: "",
    });
    const [photo, setPhoto] = useState(null);
    const [photoName, setPhotoName] = useState("");
    const [errors, setErrors] = useState({});
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);

    const fileInputRef = useRef(null);

    const openModal = (type, item = null) => {
        if (type === "Edit" && item) {
            setIsEditing(true);
            setEventInput({
                id: item.id,
                title: item?.title || "",
                description: item?.description || "",
            });
            setPhoto(null);
            setPhotoName(item.photo ? item.photo.split("/").pop() : "");
        } else if (type === "Delete" && item) {
            setIsEditing(false);
        }
        setModalState({ type, open: true });
    };

    const handleEditClick = () => {
        openModal("Edit", item);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEventInput((prevInput) => ({
            ...prevInput,
            [name]: value,
        }));
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        setPhoto(file);
        setPhotoName(file.name);
    };

    const validateInputs = () => {
        const validationErrors = {};
        if (!eventInput.title) validationErrors.title = "Title is required";
        if (!eventInput.description) validationErrors.description = "Description is required";
        return validationErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateInputs();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        try {
            setIsSubmitLoading(true);
            const formData = new FormData();
            Object.entries(eventInput).forEach(([key, value]) => {
                formData.append(key, value);
            });
            if (photo) {
                formData.append("photo", photo);
            }
            const resp = await updateEvent(formData);
            toast.success(resp.data.message);

            // Update the existing event in the data array
            const updatedEvent = resp.data.results;
            setData((prevData) => prevData.map(ev => ev.id === updatedEvent.id ? updatedEvent : ev));

            setModalState({ type: "View", open: false });
            setEventInput({ id: "", title: "", description: "" });
            setPhoto(null);
            setPhotoName("");
        } catch (error) {
            toast.error(error.response?.data?.message || "An error occurred");
        } finally {
            setIsSubmitLoading(false);
        }
    };

    const handleDelete = async () => {
        try {
            const resp = await deleteEvent(item.id);
            toast.success(resp.data.message);
            setData(data.filter(ev => ev.id !== item.id));
            setModalState({ type: "View", open: false });
        } catch (error) {
            toast.error(error.response?.data?.message || "An error occurred");
        }
    };

    const handleDeletePhoto = () => {
        setPhoto(null);
        setPhotoName("");
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    };

    return (
        <>
            <div
                onClick={() => openModal("View", item)}
                className="p-4 shadow-lg transition-all duration-500 hover:shadow-xl dark:bg-slate-950 dark:text-white"
            >
                <div className="overflow-hidden">
                    <img
                        src={`${process.env.REACT_APP_BASE_URL}${item?.photo}`}
                        alt="No image"
                        className="mx-auto h-[250px] w-full object-cover transition duration-700 hover:skew-x-2 hover:scale-110"
                    />
                </div>
                <div className="flex justify-between pt-2 text-slate-600">
                    <p>{item?.created_at}</p>
                    <p className="line-clamp-1">By {item?.upload_by}</p>
                </div>
                <div className="space-y-2 py-3">
                    <h1 className="line-clamp-1 font-bold">{item?.title}</h1>
                    <p className="line-clamp-2">{item?.description}</p>
                </div>
            </div>

            <Modal show={modalState.open} onHide={() => setModalState({ ...modalState, open: false })} className="event-modal">
                <Modal.Header closeButton>
                    <Modal.Title className="text-white">
                        {modalState.type === "Edit" ? "Edit Event" : modalState.type === "Delete" ? "Confirm Deletion" : "Event Detail"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalState.type === "Edit" ? (
                        <form onSubmit={handleSubmit} className="event-form">
                            <div className="event-form-group">
                                <label htmlFor="title" className="event-form-label">Event Title:</label>
                                <input
                                    id="title"
                                    type="text"
                                    name="title"
                                    className={`form-control ${errors.title ? "is-invalid" : ""}`}
                                    placeholder="Event Title"
                                    value={eventInput.title}
                                    onChange={handleChange}
                                />
                                {errors.title && <div className="invalid-feedback">{errors.title}</div>}
                            </div>
                            <div className="event-form-group">
                                <label htmlFor="description" className="event-form-label">Event Description:</label>
                                <textarea
                                    id="description"
                                    name="description"
                                    className={`form-control ${errors.description ? "is-invalid" : ""}`}
                                    placeholder="Description"
                                    rows="4"
                                    value={eventInput.description}
                                    onChange={handleChange}
                                ></textarea>
                                {errors.description && <div className="invalid-feedback">{errors.description}</div>}
                            </div>
                            <div className="event-form-group mb-3">
                                <label htmlFor="photo" className="event-form-label">Upload Event Image:</label>
                                <input
                                    type="file"
                                    id="photo"
                                    accept="image/*"
                                    className="form-control"
                                    onChange={handleImageUpload}
                                    ref={fileInputRef}
                                />
                                {photoName && (
                                    <div className="photo-preview mt-3">
                                        <img
                                            src={photo ? URL.createObjectURL(photo) : `${process.env.REACT_APP_BASE_URL}${item?.photo}`}
                                            alt="Event"
                                            className="preview-img"
                                        />
                                        <button
                                            type="button"
                                            className="delete-photo-btn"
                                            onClick={handleDeletePhoto}
                                        >
                                            <FaTrash />
                                        </button>
                                    </div>
                                )}
                            </div>
                            <button className="btn text-white bg-black" type="submit" disabled={isSubmitLoading}>
                                {isSubmitLoading ? "Saving..." : "Save Changes"}
                            </button>
                            <button className="btn btn-secondary btn-close" type="button" onClick={() => setModalState({ ...modalState, open: false })}>Close</button>
                        </form>
                    ) : modalState.type === "Delete" ? (
                        <p>Are you sure you want to delete this event?</p>
                    ) : (
                        <>
                            <div className="py-2 px-4">
                                <img
                                    src={`${process.env.REACT_APP_BASE_URL}${item?.photo}`}
                                    alt="No image"
                                    className="mx-auto h-[250px] w-full object-cover"
                                />
                                <div className="py-3">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h1 className="font-bold">{item?.title}</h1>
                                        <div className="d-flex justify-content-end align-items-center">
                                            <button
                                                onClick={handleEditClick}
                                                className="btn btn-sm text-white bg-black me-2"
                                            >
                                                <i className="fas fa-pencil-alt me-2"></i> Edit
                                            </button>
                                            <button
                                                className="btn btn-sm btn-danger"
                                                onClick={() => openModal("Delete", item)}
                                            >
                                                <i className="fas fa-trash-alt me-2"></i> Delete
                                            </button>
                                        </div>
                                    </div>
                                    <p className="text-slate-600">{item?.created_at}</p>
                                    <p>By {item?.upload_by}</p>
                                    <p>{item?.description}</p>
                                </div>
                            </div>
                        </>
                    )}
                </Modal.Body>
                {modalState.type === "Delete" && (
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setModalState({ ...modalState, open: false })}>
                            Cancel
                        </Button>
                        <Button variant="danger" onClick={handleDelete}>
                            Confirm
                        </Button>
                    </Modal.Footer>
                )}
            </Modal>
        </>
    );
};

export default EventDetails;
