import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Logo from "../../../assets/img/brand/logo.png";
import "../Login.css";
import useWindowSize from "../../../constant/WindowSize";
import { passwordReset } from "../../../services/ProfileService";
import { toast } from "react-toastify";

const PasswordReset = ({ goToLogin }) => {
  const [email, setEmail] = useState("");
  const [captchaText, setCaptchaText] = useState("");
  const [userInput, setUserInput] = useState("");
  const [emailError, setEmailError] = useState("");
  const [captchaError, setCaptchaError] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const size = useWindowSize();
  const isMobile = size.width <= 768;

  useEffect(() => {
    generateCaptcha();
  }, []);

  const generateCaptcha = () => {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let captcha = "";
    for (let i = 0; i < 6; i++) {
      captcha += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    setCaptchaText(captcha);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let valid = true;

    if (!email) {
      setEmailError("Email is required.");
      valid = false;
    } else {
      setEmailError("");
    }

    if (!userInput) {
      setCaptchaError("CAPTCHA is required.");
      valid = false;
    } else if (userInput !== captchaText) {
      setCaptchaError("CAPTCHA does not match. Please try again.");
      generateCaptcha();
      valid = false;
    } else {
      setCaptchaError("");
    }

    if (!valid) return;

    setLoading(true);
    try {
      const response = await passwordReset(email);
      toast.success(response.data.message);
      goToLogin(); // Go back to the login form after success
    } catch (error) {
      setError("An error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {isMobile ? (
        <div className="content" style={{ width: "95%" }}>
          <div>
            {" "}
            <Link to="/" className="text-white">
              <i className="fa-solid fa-house ms-1"></i>
            </Link>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <img
              src={Logo}
              alt="Logo"
              className="logo"
              style={{
                width: "70px",
                height: "70px",
                boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                borderRadius: "50%",
              }}
            />
          </div>
          <div className="club-title-container d-flex align-items-center justify-content-center">
            <h1
              className="club-title"
              style={{
                fontFamily: "'Raleway', sans-serif",
                fontSize: "2em",
                color: "white",
                textShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
                margin: "0",
              }}
            >
              Rourkela Club
            </h1>
          </div>
          <small
            className="mb-3 d-flex justify-content-center"
            style={{ fontSize: "0.8em", color: "white" }}
          >
            Enter your email to get a reset password link
          </small>

          <div className="formTitle">
            <span className="formTitleLink">Forgot Password</span>
          </div>

          <div className="formCenter">
            <form className="formFields mt-4" onSubmit={handleSubmit}>
              <div className="formField">
                <label
                  className="formFieldLabel"
                  htmlFor="email"
                  style={{ color: "#ffffffcf" }}
                >
                  Email Id
                </label>
                <input
                  type="email"
                  value={email}
                  className="formFieldInput"
                  placeholder="Enter Email"
                  onChange={(e) => setEmail(e.target.value)}
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                    color: "#fff",
                    border: "1px solid rgba(255, 255, 255, 0.2)",
                    borderRadius: "6px",
                    padding: "10px",
                    outline: "none",
                    boxShadow: "0px 0px 10px rgba(255, 255, 255, 0.1)",
                  }}
                />
                {emailError && (
                  <span className="form-error">
                    {emailError}
                  </span>
                )}
              </div>

              <div className="formField w-50">
                <div className="d-flex align-items-center justify-contents-between">
                  <div
                    className="captcha-display"
                    style={{
                      fontSize: "1.2em",
                      color: "#ffdd57",
                      backgroundColor: "#2d2d2d",
                      padding: "10px",
                      borderRadius: "6px",
                      letterSpacing: "4px",
                      marginBottom: "10px",
                      userSelect: "none",
                      pointerEvents: "none",
                    }}
                  >
                    {captchaText}
                  </div>
                  <button
                    type="button"
                    onClick={generateCaptcha}
                    className="captcha-refresh btn btn-sm btn-outline-light ms-3"
                    style={{ padding: "8px 12px" }}
                  >
                    <i className="fa-solid fa-arrows-rotate"></i>
                  </button>
                </div>
                <label
                  className="formFieldLabel"
                  htmlFor="cpatcha"
                  style={{ color: "#ffffffcf" }}
                >
                  Enter Captcha
                </label>
                <div className="d-flex align-items-center mb-3">
                  <input
                    type="text"
                    value={userInput}
                    className="input-captcha"
                    placeholder="Enter CAPTCHA"
                    onChange={(e) => setUserInput(e.target.value)}
                    style={{
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                      color: "#fff",
                      border: "1px solid rgba(255, 255, 255, 0.2)",
                      borderRadius: "6px",
                      padding: "10px",
                      outline: "none",
                      boxShadow: "0px 0px 10px rgba(255, 255, 255, 0.1)",
                      width: "100%",
                    }}
                  />

                </div>


                {captchaError && (
                  <span className="form-error">
                    {captchaError}
                  </span>
                )}
                {error && (
                  <span className="form-error">
                    {error}
                  </span>
                )}
              </div>

              <div className="formField">
                <button className="formFieldButton my-4" disabled={loading}>
                  {loading ? (
                    <div className="spinner-border text-light" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    "Send Link"
                  )}
                </button>
                <Link
                  className="formFieldLink mx-2"
                  style={{ color: "#ffffffcf" }}
                  onClick={goToLogin}
                >
                  Back to Login
                </Link>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <div className="col-5  appForm">
          <div className="d-flex align-items-center justify-content-center">
            <img
              src={Logo}
              alt="Logo"
              className="logo"
              style={{
                width: "70px",
                height: "70px",
                boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                borderRadius: "50%",
              }}
            />
          </div>
          <div className="club-title-container d-flex align-items-center justify-content-center">
            <h1
              className="club-title"
              style={{
                fontFamily: "'Raleway', sans-serif",
                fontSize: "2em",
                color: "white",
                textShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
                margin: "0",
              }}
            >
              Rourkela Club
            </h1>
          </div>
          <small className="mb-3" style={{ fontSize: "0.8em", color: "white" }}>
            Enter your email to get a reset password link
          </small>
          <div className="formTitle">
            <span className="formTitleLink">Forgot Password</span>
          </div>

          <div className="formCenter">
            <form className="formFields mt-2" onSubmit={handleSubmit}>
              <div className="formField">
                <label
                  className="formFieldLabel"
                  htmlFor="email"
                  style={{ color: "#ffffffcf" }}
                >
                  Email Id
                </label>
                <input
                  type="email"
                  value={email}
                  className="formFieldInput"
                  placeholder="Enter Email"
                  onChange={(e) => setEmail(e.target.value)}
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                    color: "#fff",
                    border: "1px solid rgba(255, 255, 255, 0.2)",
                    borderRadius: "6px",
                    padding: "10px",
                    outline: "none",
                    boxShadow: "0px 0px 10px rgba(255, 255, 255, 0.1)",
                  }}
                />
                {emailError && (
                  <span className="form-error">
                    {emailError}
                  </span>
                )}
              </div>

              <div className="formField">
                <div className="d-flex align-items-center">
                  <div className="captcha-display" style={{ userSelect: "none", pointerEvents: "none" }}>
                    <div className="captcha-text">{captchaText}</div>
                  </div>

                  <button
                    type="button"
                    onClick={generateCaptcha}
                    className="captcha-refresh btn btn-sm btn-outline-light ms-3"
                  >
                    <i className="fa-solid fa-arrows-rotate text-base"></i>
                  </button>
                </div>
                <label
                  className="formFieldLabel"
                  htmlFor="captcha"
                  style={{ color: "#ffffffcf" }}
                >
                  Enter Captcha
                </label>
                <input
                  type="text"
                  value={userInput}
                  className="formFieldInput"
                  placeholder="Enter CAPTCHA"
                  onChange={(e) => setUserInput(e.target.value)}
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                    color: "#fff",
                    border: "1px solid rgba(255, 255, 255, 0.2)",
                    borderRadius: "6px",
                    padding: "10px",
                    outline: "none",
                    boxShadow: "0px 0px 10px rgba(255, 255, 255, 0.1)",
                    width: "100%",
                  }}
                />
                {captchaError && (
                  <span className="form-error">
                    {captchaError}
                  </span>
                )}
                {error && (
                  <span className="form-error">
                    {error}
                  </span>
                )}
              </div>

              <div className="formField">
                <button className="formFieldButton my-4" disabled={loading}>
                  {loading ? (
                    <div className="spinner-border text-light" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    "Send Link"
                  )}
                </button>
                <Link
                  className="formFieldLink mx-2"
                  style={{ color: "#ffffffcf" }}
                  onClick={goToLogin}
                >
                  Back to Login
                </Link>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default PasswordReset;
