import axiosInstance from "./AxiosInstance";
export function login(email, password) {
  const postData = {
    email,
    password,
  };
  return axiosInstance.post(`api/auth/login`, postData);
}

export function signupApi(data) {
  return axiosInstance.post(`api/auth/signup`, data);
}
export function logoutApi() {
  return axiosInstance.get("api/auth/logout");
}

export function whoami() {
  return axiosInstance.get("api/auth/authorized");
}
