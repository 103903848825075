import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import classnames from "classnames";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Row,
} from "reactstrap";
import { getBookingAmountGraph } from "../../services/DashBoardService";

var colors = {
  gray: {
    100: "#f6f9fc",
    200: "#e9ecef",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#8898aa",
    700: "#525f7f",
    800: "#32325d",
    900: "#212529",
  },
  theme: {
    default: "#172b4d",
    primary: "#5e72e4",
    secondary: "#f4f5f7",
    info: "#11cdef",
    success: "#2dce89",
    danger: "#f5365c",
    warning: "#fb6340",
  },
  black: "#12263F",
  white: "#FFFFFF",
  transparent: "transparent",
};

const BookingAmountGraph = () => {
  const [dataset, setDataSet] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [timePeriod, setTimePeriod] = useState("weekly");

  const toggleTimePeriod = (tp) => {
    setTimePeriod(tp);
  };

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      setIsLoading(true);
      try {
        const resp = await getBookingAmountGraph(timePeriod);
        if (isMounted) {
          setDataSet(resp.data.results);
          setIsLoading(false);
        }
      } catch (error) {
        if (isMounted) {
          if (error.response) {
            console.log(error.response.data.message);
          } else {
            console.log(error);
          }
          setIsLoading(false);
        }
      }
    };

    fetchData();
    return () => {
      isMounted = false;
    };
  }, [timePeriod]);

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: colors.gray[600],
        },
      },
      y: {
        grid: {
          color: colors.gray[200],
        },
        ticks: {
          color: colors.gray[600],
          callback: function (value) {
            if (!(value % 10)) {
              if (value >= 1000) {
                return `₹ ${(value / 1000).toFixed(1)}K`;
              } else {
                return `₹ ${value}`;
              }
            }
          },
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: colors.theme.default,
        },
      },
      tooltip: {
        callbacks: {
          label: function (item, data) {
            var label = data.datasets[item.datasetIndex].label || "";
            var yLabel = item.raw;
            var content = "";

            if (data.datasets.length > 1) {
              content += label;
            }

            if (yLabel >= 1000) {
              content += `₹ ${(yLabel / 1000).toFixed(1)}K`;
            } else {
              content += `₹ ${yLabel}`;
            }

            return content;
          },
        },
      },
    },
  };

  return (
    <Card className="shadow CardNEW" style={{ backgroundColor: "#ecf0f1", borderRadius: "20px", padding: "20px", position: "relative" }}>
      <CardHeader
        className="bg-white shadow-sm mb-3"
        style={{ borderBottom: "none", padding: "15px", borderRadius: "20px" }}
      >
        <Row className="align-items-center justify-content-center">
          <div className="col text-left">
            <h2 className="mb-0" style={{ color: "#2c3e50", textShadow: "1px 1px 3px rgba(0, 0, 0, 0.2)" }}>
              <strong>Booking value</strong>
            </h2>
          </div>
          <div className="col">
            <Nav className="justify-content-end" pills>
              <NavItem>
                <NavLink
                  className={classnames("py-1 px-3", { active: timePeriod === "yearly" })}
                  style={{
                    backgroundColor: timePeriod === "yearly" ? "#d63031" : "transparent",
                    color: "#2c3e50",
                    borderRadius: "10px",
                    transition: "0.3s ease",
                  }}
                  onClick={() => toggleTimePeriod("yearly")}
                >
          
                  Y
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames("py-1 px-3", { active: timePeriod === "monthly" })}
                  style={{
                    backgroundColor: timePeriod === "monthly" ? "#6c5ce7" : "transparent",
                    color: "#2c3e50",
                    borderRadius: "10px",
                    transition: "0.3s ease",
                  }}
                  onClick={() => toggleTimePeriod("monthly")}
                >
                  M
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames("py-1 px-3", { active: timePeriod === "weekly" })}
                  style={{
                    backgroundColor: timePeriod === "weekly" ? "#00b894" : "transparent",
                    color: "#2c3e50",
                    borderRadius: "10px",
                    transition: "0.3s ease",
                  }}
                  onClick={() => toggleTimePeriod("weekly")}
                >
                  W
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </Row>
      </CardHeader>
      <CardBody className="p-sm-4 p-0"
          style={{
            position: "relative",
            height:  "400px",
            backgroundColor: "#ffffff",
            borderRadius: "15px",
            boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
            overflow: "hidden",
          }}>
        {isLoading ? (
            <div className="text-center my-5">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : dataset ? (
          <div className="chart" style={{ height: '100%' }}>
            <Line
              data={dataset}
              options={options}
            />
          </div>
        ) : null}
      </CardBody>
    </Card>
  );
};

export default BookingAmountGraph;
