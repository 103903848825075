import React, { useState } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CancelRefundRoomBooking, updateRoomBooking } from "../../services/RoomService";
import "./BookingStatus.css";

const UserRefundRequest = ({ booking, updateBookingData }) => {
  const [showCancelBooking, setShowCancelBooking] = useState(false);
  const [bookingToCancel, setBookingToCancel] = useState(null);
  const [loading, setLoading] = useState(false); // Add loading state

  const handleCancelShow = () => {
    setBookingToCancel(booking);
    setShowCancelBooking(true);
  };

  const handleConfirm = () => {
    if (bookingToCancel) {
      setLoading(true); // Set loading to true when the request is initiated
      if (booking?.booking_payment_source === "Razorpay") {
        const payload = { id: bookingToCancel?.refund?.payment_id };

        CancelRefundRoomBooking(payload)
          .then((resp) => {
            const { message, refund_details } = resp.data;
            updateBookingData(refund_details);
            toast.success(message);
            setShowCancelBooking(false);
          })
          .catch((error) => {
            console.log("error", error);
            if (error?.response) {
              toast.error(error.response?.data?.message);
            }
          })
          .finally(() => setLoading(false)); // Set loading to false after the request is done
      } else {
        const payload = { id: bookingToCancel.id, cancel_booking: "YES" };

        updateRoomBooking(payload)
          .then((resp) => {
            const { message, results } = resp.data;
            updateBookingData(results);
            toast.success(message);
            setShowCancelBooking(false);
          })
          .catch((error) => {
            console.log("error", error);
            if (error?.response) {
              toast.error(
                error.response?.data?.message || "An error occurred"
              );
            }
          })
          .finally(() => setLoading(false)); // Set loading to false after the request is done
      }
    }
  };

  return (
    <>
      <button className="btn btn-sm btn-danger mb-1" onClick={handleCancelShow}>
        <i className="fa-solid fa-pen-to-square"></i> Refund
      </button>
      <div style={{ fontSize: "0.6rem", color: "gray" }}>(Cancel By User)</div>

      {booking?.booking_payment_source === "Razorpay" ? (
        <Modal
          show={showCancelBooking}
          onHide={() => setShowCancelBooking(false)}
          centered="true"
        >
          <Modal.Body className=""></Modal.Body>
          <div className=" text-sm px-3 py-1">
            <strong>Payment Id : </strong>
            <span>{booking?.refund?.payment_id}</span>
          </div>
          <div className="px-5">
            <div className="refund-header">
              <i className="fas fa-money-check-alt"></i>
              <h2>Refund Initiated</h2>
              <p>
                Please review the details below before processing the refund
              </p>
            </div>
            <p className="text-sm text-center">
              <strong>Refund Requested on Date : {booking?.refund?.refund_request_date}</strong>
            </p>

            <div className="status-info">
                <div className="mb-3">
                <i className="fas fa-receipt icon-display"></i>
                  <strong>Booking ID:</strong>{" "}
                  <span>{booking?.booking_number}</span>
                </div><div className="mb-3">
                <i className="fas fa-rupee-sign icon-display"></i>
                <strong>Refund Amount:</strong>{" "}
                <span id="refundAmount">{booking?.booking_net_amount}</span>
                </div><div>
                <i className="fas fa-info-circle icon-display"></i>
                <strong>Refund Reason:</strong>{" "}
                <span id="refundAmount">{booking?.cancel_reason || "N/A"}</span>
                </div>
              </div>

            <button
              type="submit"
              className="btn btn-success btn-block mb-4"
              onClick={handleConfirm}
              disabled={loading} // Disable button during loading
            >
              {loading ? <Spinner animation="border" size="sm" /> : <><i className="fas fa-paper-plane"></i> Process Refund</>}
            </button>
          </div>
        </Modal>
      ) : (
        <Modal
          show={showCancelBooking}
          onHide={() => setShowCancelBooking(false)}
          centered="true"
          size="sm"
        >
          <Modal.Body>
            <div className="text-sm p-2">
              <strong>Transaction Id : </strong>
              <span>{booking?.refund?.payment_id}</span>
            </div>
            <div className="px-3">
              <div className="refund-header">
                <i className="fas fa-money-check-alt"></i>
                <h2>Refund Initiated</h2>
                <p>Details of your offline payment and refund initiation</p>
              </div>
              <p className="text-sm text-center">
                <strong>Refund Requested on : {booking?.refund?.refund_request_date}</strong>
              </p>

              <div className="status-info">
                <i className="fas fa-check-circle"></i>
                <strong>Payment Source:</strong>{" "}
                {booking?.booking_payment_source}
                <div className="my-3">
                <i className="fas fa-receipt icon-display"></i>
                  <strong>Booking ID:</strong>{" "}
                  <span>{booking?.booking_number}</span>
                </div><div className="mb-3">
                <i className="fas fa-rupee-sign icon-display"></i>
                <strong>Refund Amount:</strong>{" "}
                <span id="refundAmount">{booking?.booking_net_amount}</span>
                </div><div>
                <i className="fas fa-info-circle icon-display"></i>
                <strong>Refund Reason:</strong>{" "}
                <span id="refundAmount">{booking?.cancel_reason || "N/A"}</span>
                </div>
              </div>

              
              <div className="d-flex align-items-center justify-content-center mb-3">
                <button
                  type="submit"
                  className="btn btn-danger btn-block"
                  onClick={() => setShowCancelBooking(false)}
                  disabled={loading}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-success btn-block"
                  onClick={handleConfirm}
                  disabled={loading} // Disable button during loading
                >
                  {loading ? <Spinner animation="border" size="sm" /> : <><i className="fas fa-paper-plane"></i> Refund</>}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default UserRefundRequest;
