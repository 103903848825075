import React, { useState, useEffect } from "react";
import "./BookingPage.css";
import { getApplyCouponList } from "../../../../services/SettingService";
import { Spinner } from "reactstrap";

const CouponPage = ({ onCouponSelect, price, userRole }) => {
  const [selectedCoupon, setSelectedCoupon] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    getApplyCouponList()
      .then((resp) => {
        const today = new Date();
        const filteredCoupons = resp.data.results
          .filter((coupon) => {
            const couponStartDate = new Date(coupon.valid_from);
            return couponStartDate <= today &&
              (coupon.user_limit === 0 || coupon.usage_count < coupon.user_limit);
          })
          .sort((a, b) => {
            return (price >= b.minimum_spend ? 1 : 0) - (price >= a.minimum_spend ? 1 : 0);
          });

        setData(filteredCoupons);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error.response ? error.response.data.message : error);
        setIsLoading(false);
      });
  }, [price]);


  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { month: "long", day: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    const day = date.getDate();
    const suffix =
      day === 1 || day === 21 || day === 31
        ? "st"
        : day === 2 || day === 22
          ? "nd"
          : day === 3 || day === 23
            ? "rd"
            : "th";

    return `${formattedDate}${suffix}`;
  }

  const handleSelectCoupon = (coupon) => {
    setSelectedCoupon(coupon);
    onCouponSelect(coupon);
  };

  return (
    <div className="card-body">
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner style={{ width: '2rem', height: '2rem' }} color="primary" />
        </div>
      ) : (
        <div className="d-flex justify-content-center row">
          {data.length > 0 ? (
            data.map((coupon) => (
              <div className="col-md-6 mb-3" key={coupon.id}>
                <div className="bg-white row position-relative" style={{ border: "1px dotted black" }}>
                  <div
                    className="col-7 p-2 position-relative"
                    style={{
                      backgroundColor: `${price >= coupon.minimum_spend ? "gold" : "#e5e7ec"}`,
                    }}
                  >
                    <span style={{ fontSize: "0.7rem" }}>
                      {coupon.discount_value} {coupon.discount_type === "Percentage" ? "%" : "OFF"}
                    </span>
                    <p className="minimum-spend text-dark" style={{ fontSize: "0.6rem" }}>
                      <strong>Minimum spend: </strong>
                      <strong>₹{coupon.minimum_spend}</strong>
                    </p>
                    {/* Circles */}
                    <div className="circles-container">
                      <div className="circle"></div>
                      <div className="circle"></div>
                      <div className="circle"></div>
                      <div className="circle"></div>
                      <div className="circle"></div>
                    </div>
                  </div>
                  <div className="col-5 text-center p-1">
                    <button
                      className="btn btn-sm btn-success text-white px-1 code rounded mb-2"
                      onClick={() => handleSelectCoupon(coupon)}
                      disabled={price < coupon.minimum_spend}
                      style={{
                        fontSize: "0.7rem",
                        color: price >= coupon.minimum_spend ? "#056608" : "black",
                        cursor: price < coupon.minimum_spend ? "not-allowed" : "pointer",
                        pointerEvents: price < coupon.minimum_spend ? "none" : "auto",
                        opacity: price < coupon.minimum_spend ? "0.5" : "1",
                      }}
                    >
                      Apply
                    </button>
                    <div className="text-danger" style={{ fontSize: "0.5rem" }}>
                      <strong>Valid till {formatDate(coupon.valid_to)}</strong>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center">No coupons available</div>
          )}
        </div>
      )}
    </div>
  );


};

export default CouponPage;
