import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import { addDays, format, parseISO } from "date-fns";
import "flatpickr/dist/themes/material_blue.css";
import useWindowSize from "../../../../constant/WindowSize";
import CalendarPopUp from "../PopUpPage/CalendarPopUp";
import { Form } from "react-bootstrap";
import { getFilterRoomList } from "../../../../services/RoomService";
import Loader from "../../../Loader/Loader";

function FilterPage({ user }) {
  const [dates, setDates] = useState([new Date(), addDays(new Date(), 7)]);
  const [roomType, setRoomType] = useState("All");
  const [slotType, setSlotType] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [roomList, setRoomList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSlotTypeDisabled, setIsSlotTypeDisabled] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const fetchRoomList = useCallback((query, type, startDate, endDate, slot) => {
    setIsLoading(true);

    getFilterRoomList(query, type, startDate, endDate, slot)
      .then((response) => {
        if (response) {
          const uniqueRooms = processRoomList(response.data.results);
          setRoomList(uniqueRooms);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching rooms:", error);
        setRoomList([]);
      });
  }, []);

  const processRoomList = (rooms) => {
    const roomMap = new Map();
    rooms.forEach((item) => {
      if (!roomMap.has(item.room?.id)) {
        roomMap.set(item.room?.id, [item]);
      }
    });
    return Array.from(roomMap.values()).flat();
  };

  useEffect(() => {
    if (location.state) {
      const { searchQuery, roomType, startDate, endDate, slotType } =
        location.state;
      if (startDate && endDate) {
        const start = parseISO(startDate);
        const end = parseISO(endDate);
        if (!isNaN(start) && !isNaN(end)) {
          setDates([start, end]);
          fetchRoomList(
            searchQuery,
            roomType,
            startDate,
            endDate,
            roomType === "Mandap" ? "Full Day" : ""
          );
        } else {
          console.error("Invalid date received from location.state", {
            startDate,
            endDate,
          });
        }
      }
      setSearchQuery(searchQuery || "");
      setRoomType(roomType || "All");
      if (roomType === "Mandap") {
        setSlotType("Full Day");
        setIsSlotTypeDisabled(true);
      } else {
        setSlotType(slotType || "");
        setIsSlotTypeDisabled(false);
      }
    }
  }, [location.state, fetchRoomList]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const size = useWindowSize();
  const isMobile = size.width <= 1024;

  const handleDateChange = (selectedDates) => {
    if (selectedDates.length === 2) {
      const formattedStartDate = format(selectedDates[0], "yyyy-MM-dd");
      const formattedEndDate = format(selectedDates[1], "yyyy-MM-dd");

      setDates(selectedDates);
      fetchRoomList(
        searchQuery,
        roomType,
        formattedStartDate,
        formattedEndDate,
        slotType
      );
    } else {
      console.error("Invalid date range selected", selectedDates);
    }
  };

  const handleRoomTypeChange = (e) => {
    const selectedRoomType = e.target.value;
    setRoomType(selectedRoomType);

    if (selectedRoomType === "Mandap") {
      setSlotType("Full Day");
      setIsSlotTypeDisabled(true);
    } else {
      setSlotType("");
      setIsSlotTypeDisabled(false);
    }

    fetchRoomList(
      searchQuery,
      selectedRoomType,
      format(dates[0], "yyyy-MM-dd"),
      format(dates[1], "yyyy-MM-dd"),
      selectedRoomType === "Mandap" ? "Full Day" : ""
    );
  };

  const handleSlotTypeChange = (e) => {
    const selectedSlotType = e.target.value;
    setSlotType(selectedSlotType);
    fetchRoomList(
      searchQuery,
      roomType,
      format(dates[0], "yyyy-MM-dd"),
      format(dates[1], "yyyy-MM-dd"),
      selectedSlotType
    );
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    fetchRoomList(
      query,
      roomType,
      format(dates[0], "yyyy-MM-dd"),
      format(dates[1], "yyyy-MM-dd"),
      slotType
    );
  };

  const truncateDescription = (description) => {
    const words = description?.split(" ");
    return words?.length > 50
      ? words.slice(0, 50).join(" ") + "..."
      : description;
  };

  const handleShowDetails = (room) => {
    navigate(`/room-details/${room.id}`, { state: { room } });
  };

  const handleBookNow = (room) => {
    if (room?.id) {
      navigate(`/booking/${room.id}`, { state: { room } });
    } else {
      console.error("Room ID is missing");
    }
  };

  return (
    <>
      <div
        className="dark:bg-gray-900 dark:text-white bg-gray-50"
        style={{ minHeight: "100vh" }}
      >
        <style jsx>{`
          .form-select {
            background-color: white !important;
            border: 1px solid #ced4da;
            color: #6c757d;
            font-size: 0.875rem;
            padding: 0.375rem 0.75rem;
            appearance: none;
            background: url("data:image/svg+xml;charset=US-ASCII,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'><path fill='%23999' d='M2 5L0 3h4z'/></svg>")
              no-repeat right 1rem center;
            background-size: 12px 12px;
            padding-right: 2rem;
            width: 100%;
            border-radius: 0.375rem;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
            transition: border-color 0.3s, box-shadow 0.3s;
          }

          .form-select:focus {
            border-color: black !important;
          }

          .form-select option {
            padding: 0.5rem;
          }
        `}</style>
        <section data-aos="fade-up" className="container mt-5 px-3">
          {isMobile ? (
            <>
              <div className="row">
                <div className="col-sm-12 bg-yellow mb-3 rounded-lg p-1">
                  <div className="row py-2">
                    <div className="col-12 mb-2">
                      <Flatpickr
                        value={dates}
                        onChange={handleDateChange}
                        options={{
                          mode: "range",
                          dateFormat: "Y-m-d",
                          minDate: "today",
                        }}
                        className="form-control custom-flatpickr  custom-date-range"
                        placeholder="Select date range"
                      />
                    </div>
                    <div className="col-6 mb-2">
                      <Form.Select
                        id="room_type"
                        name="room_type"
                        className="form-select py-2"
                        value={roomType}
                        onChange={handleRoomTypeChange}
                      >
                        <option value="">Room Type</option>
                        <option value="Room">Room</option>
                        <option value="Lounge">Lounge</option>
                        <option value="Hall">Hall</option>
                        <option value="Mandap">Mandap</option>
                      </Form.Select>
                    </div>
                    <div className="col-6 mb-2">
                      <Form.Select
                        id="slot_type"
                        name="slot_type"
                        className="form-select py-2"
                        value={slotType}
                        onChange={handleSlotTypeChange}
                        disabled={isSlotTypeDisabled}
                      >
                        <option value="" disabled hidden>
                          Select Slot Type
                        </option>
                        <option value="All">All</option>
                        <option value="Full Day">Full Day</option>
                        <option value="Time Slot">Time Slot</option>
                      </Form.Select>
                    </div>

                    <div className="col-8">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={handleSearchChange}
                      />
                    </div>
                    <div className="col-4 d-flex align-items-center justify-content-between">
                      <button className="text-white bg-black py-2 px-4 rounded hover:scale-105 transition-transform">
                        Search
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row">
                    {isLoading ? (
                      <div
                        className="text-center my-7"
                        style={{ width: "100%", height: "100%" }}
                      >
                      <Loader />
                      </div>
                    ) : roomList.length > 0 ? (
                      roomList.map((item, idx) => (
                        <div className="col-md-12 col-sm-12 mb-3" key={idx}>
                          <div className="blog-singe no-margin row">
                            <div className="col-sm-5 blog-img-tab">
                              <img
                                src={`${process.env.REACT_APP_BASE_URL}${item.room?.roomphoto[0]?.photo}`}
                                alt=""
                                className="w-full h-full object-cover rounded-lg"
                                onClick={() => handleShowDetails(item.room)}
                              />
                            </div>
                            <div className="col-sm-7 blog-content-tab">
                              <h2 className="text-xl font-bold">
                                {item.room?.name}
                              </h2>
                              <h5 className="mb-2">
                                <strong>Price: </strong>
                                {item.room?.price}
                              </h5>
                              <p className="line-clamp-1 text-gray-600 flex mb-3">
                                {truncateDescription(item.room?.description)}
                              </p>
                              <div className="mb-4">
                                <small
                                  className="border-end me-3 pe-3"
                                  style={{ color: "#777" }}
                                >
                                  <i className="fa-regular fa-clock"></i>{" "}
                                  {item.room?.room_slot_type}
                                </small>
                                <small
                                  className="border-end me-3 pe-3"
                                  style={{ color: "#777" }}
                                >
                                  <i className="fa-solid fa-bed"></i>{" "}
                                  {item.room?.room_type}
                                </small>
                              </div>
                              <div className="d-flex align-items-center justify-content-between">
                                {user ? (
                                  <div>
                                    <button
                                      className="text-white bg-black px-3 py-1 rounded duration-200"
                                      onClick={() => handleBookNow(item.room)}
                                    >
                                      Book Now
                                    </button>
                                  </div>
                                ) : (
                                  <div>
                                    <button
                                      className="text-white bg-black px-3 py-1 rounded duration-200"
                                      onClick={() =>
                                        navigate(
                                          `/login?next_url=${`/room-details/${item.room?.id}`}`
                                        )
                                      }
                                    >
                                      Book Now
                                    </button>
                                  </div>
                                )}
                                <CalendarPopUp
                                  roomId={item ? item.room.id : null}
                                  roomSlotType={
                                    item ? item.room.room_slot_type : null
                                  }
                                  room={item}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="no-results-found text-center w-screen h-screen my-7">
                        <p>No results found</p>
                        <i className="fas fa-exclamation-circle text-5xl text-gray-400 mt-4"></i>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="row">
              <div className="col-sm-12 bg-yellow mb-3 rounded-lg p-1">
                <div className="row py-2 align-items-center">
                  <div className="col-3">
                    <Flatpickr
                      value={dates}
                      onChange={handleDateChange}
                      options={{
                        mode: "range",
                        dateFormat: "Y-m-d",
                        minDate: "today",
                      }}
                      className="form-control custom-flatpickr custom-date-range"
                      placeholder="Select date range"
                    />
                  </div>

                  <div className="col-2">
                    <Form.Select
                      id="room_type"
                      name="room_type"
                      className="form-select py-2"
                      value={roomType}
                      onChange={handleRoomTypeChange}
                    >
                      <option value="">Room Type</option>
                      <option value="Room">Room</option>
                      <option value="Lounge">Lounge</option>
                      <option value="Hall">Hall</option>
                      <option value="Mandap">Mandap</option>
                    </Form.Select>
                  </div>

                  <div className="col-2">
                    <Form.Select
                      id="slot_type"
                      name="slot_type"
                      className="form-select py-2"
                      value={slotType}
                      onChange={handleSlotTypeChange}
                      disabled={isSlotTypeDisabled}
                    >
                      <option value="" disabled hidden>
                        Select Slot Type
                      </option>
                      <option value="All">All</option>
                      <option value="Full Day">Full Day</option>
                      <option value="Time Slot">Time Slot</option>
                    </Form.Select>
                  </div>
                  <div className="col-3">
                    <input
                      type="text"
                      className="form-control w-100"
                      placeholder="Search..."
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                  </div>

                  <div className="col-1 text-center">
                    <button className="text-white bg-black py-2 px-4 rounded hover:scale-105 transition-transform">
                      Search
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="row">
                  {isLoading ? (
                    <div
                      className="text-center my-7"
                      style={{ width: "100%", height: "100%" }}
                    >
                      <div
                        className="spinner-grow text-black me-2"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                      <div
                        className="spinner-grow text-black me-2"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                      <div
                        className="spinner-grow text-black me-2"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                      <div className="spinner-grow text-black" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : roomList.length > 0 ? (
                    roomList.map((item, idx) => (
                      <div className="col-md-12 col-sm-12 mb-3" key={idx}>
                        <div className="blog-singe no-margin row">
                          <div className="col-sm-5 blog-img-tab">
                            <img
                              src={`${process.env.REACT_APP_BASE_URL}${item.room?.roomphoto[0]?.photo}`}
                              alt=""
                              className="w-full h-full object-cover rounded-lg"
                              onClick={() => handleShowDetails(item.room)}
                            />
                          </div>
                          <div className="col-sm-7 blog-content-tab">
                            <h2 className="text-xl font-bold">
                              {item.room?.name}
                            </h2>
                            <h5 className="mb-2">
                              <strong>Price: </strong>
                              {item.room?.price}
                            </h5>
                            <p className="text-gray-600 flex mb-3">
                              {truncateDescription(item.room?.description)}
                            </p>
                            <div className="mb-4">
                              <small
                                className="border-end me-3 pe-3"
                                style={{ color: "#777" }}
                              >
                                <i className="fa-regular fa-clock"></i>{" "}
                                {item.room?.room_slot_type}
                              </small>
                              <small
                                className="border-end me-3 pe-3"
                                style={{ color: "#777" }}
                              >
                                <i className="fa-solid fa-bed"></i>{" "}
                                {item.room?.room_type}
                              </small>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                            {user ? (
                                  <div>
                                    <button
                                      className="text-white bg-black px-3 py-1 rounded duration-200"
                                      onClick={() => handleBookNow(item.room)}
                                    >
                                      Book Now
                                    </button>
                                  </div>
                                ) : (
                                  <div>
                                    <button
                                      className="text-white bg-black px-3 py-1 rounded duration-200"
                                      onClick={() =>
                                        navigate(
                                          `/login?next_url=${`/room-details/${item.room?.id}`}`
                                        )
                                      }
                                    >
                                      Book Now
                                    </button>
                                  </div>
                                )}
                              <CalendarPopUp
                                roomId={item ? item.room?.id : null}
                                roomSlotType={
                                  item ? item?.room?.room_slot_type : null
                                }
                                room={item}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="no-results-found text-center w-screen h-screen my-7">
                      <p>No results found</p>
                      <i className="fas fa-exclamation-circle text-5xl text-gray-400 mt-4"></i>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </section>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(FilterPage);
