import React from 'react';
import * as XLSX from 'xlsx';
import { BiExport } from "react-icons/bi";
import useWindowSize from '../constant/WindowSize';

const ExcelDownload = ({ headers, data, fileName, fetchFullData }) => {
  const handleDownload = async (option) => {
    let dataToDownload = [];

    if (option === 'current') {
      dataToDownload = data;
    } else if (option === 'all') {
      dataToDownload = await fetchFullData();
    }

    const formattedData = dataToDownload.map((item) =>
      headers.reduce((acc, header) => {
        const value = header.accessor(item);

        if (React.isValidElement(value)) {
         
          let extractedText = '';
          if (Array.isArray(value.props.children)) {
            extractedText = value.props.children
              .map((child) => (typeof child === 'string' ? child : (child.props?.children || '')))
              .join(' ');
          } else {
            extractedText = typeof value.props.children === 'string'
              ? value.props.children
              : value.props.children?.props?.children || '';
          }
          acc[header.label] = extractedText;
        } else {
       
          acc[header.label] = value;
        }

        return acc;
      }, {})
    );


    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    worksheet["!cols"] = headers.map((header) => ({ wch: header.width || 15 }));


    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");


    XLSX.writeFile(workbook, `${fileName || "table_data"}.xlsx`);
  };

  const size = useWindowSize();
  const isMobile = size.width <= 1024;

  return (
    <div className="dropdown-filter-status">
      <button
        className="btn text-white btn-success dropdown-toggle d-flex align-items-center"
        type="button"
        id="roomTypeDropdown"
      >
        <BiExport className="me-2" style={{ fontSize: '24px' }} />
      </button>
      <ul
        className="dropdown-filter-menu w-100"
        style={{ borderRadius: "1px" }}
        aria-labelledby="roomTypeDropdown"
      >
        <li>
          <a
            className="dropdown-filter-item cursor-pointer"
            onClick={() => handleDownload('current')}
          >
            Current Page
          </a>
          <a
            className="dropdown-filter-item cursor-pointer"
            onClick={() => handleDownload('all')}
          >
            All Pages
          </a>
        </li>
      </ul>
    </div>
  );
};

export default ExcelDownload;
