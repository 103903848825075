import React, { useEffect, useState, useRef } from "react";
import RenderCalendar from "./RenderCalendar";
import { useLocation } from "react-router-dom";
import { format, parseISO } from "date-fns";
import DynamicSelectInput from "../../../../constant/SelectInput"; 
import { getRoomAvailable } from "../../../../services/RoomService";

const BookingForm = ({
  room,
  bookInput,
  handleBook,
  calendarRef,
  userId,
  bookedDisableDateList,
  setBookedDisableDateList,
  showConfirmPage,
  sendMessage,
  dateError,
  setDateError,
  setBookInput,
  isMobile,
  socket,
  isBookingLoading,
  userRole,
}) => {
    const [availableData, setAvailableData] = useState([]);
    const [socketBookingProgress, setSocketBookingProgress] = useState([]);
    const [disableDateList, setDisableDateList] = useState([]);
    const location = useLocation();

    useEffect(() => {
      if (location.state?.date) {
        setBookInput((prev) => ({
          ...prev,
          booking_date: parseISO(location?.state?.date)
        }));
      }
      if (location.state?.slotType) {
        setBookInput((prev) => ({
          ...prev,
          booking_time: location?.state?.slotType
        }));
      }
    }, [location.state]);
    
      const handleUpdateSocketData = (booking_time, booking_dates) => {
        let updateSocketData = socketBookingProgress.map((item) => {
          if (item.booking_time === booking_time) {
            return {
              booking_time,
              booking_date: [...item.booking_date, ...booking_dates],
            };
          }
          return { booking_time, booking_date: booking_dates };
        });
    
        if (updateSocketData.length === 0) {
          updateSocketData = [{ booking_time, booking_date: booking_dates }];
        }
        setSocketBookingProgress(updateSocketData);
      };
    
      const handleSocketData = (data) => {
        const room_id = room?.id;
        if (data.room_id === room_id) {
          if (data.action === "BOOKING_INIT" && data.user_id === userId) {
            if (data.booking_dates.length > 0) {
              handleUpdateSocketData(data.booking_time, [...data.booking_dates]);
              if (bookInput.booking_time === data.booking_time) {
                setDisableDateList((prevDates) => [
                  ...new Set([...prevDates, ...data.booking_dates]),
                ]);
              }
            }
          } else if (data.action === "BOOKING_START") {
            const newDisbaledDates = [...disableDateList, data.booking_date];
            if (bookInput.booking_time === data.booking_time) {
              setDisableDateList((prevDates) => [
                ...new Set([...prevDates, ...newDisbaledDates]),
              ]);
            }
            handleUpdateSocketData(data.booking_time, newDisbaledDates);
          } else if (data.action === "BOOKING_CANCEL") {
            console.log("sss cancel", disableDateList, data.booking_date);
            const updatedDisableDateList = disableDateList.filter(
              (item) => item !== data.booking_date
            );
            handleUpdateSocketData(data.booking_time, updatedDisableDateList);
            if (bookInput.booking_time === data.booking_time) {
              setDisableDateList((prevDates) => [
                ...new Set([...updatedDisableDateList]),
              ]);
            }
          }
        }
      };
    
      useEffect(() => {
        if (bookInput.booking_time) {
          socket.current = new WebSocket(
            `${process.env.REACT_APP_WS_BASE_URL}/ws/bookings`
          );
          socket.current.onopen = () => {
            const { room_id, booking_time } = bookInput;
            const socketPayload = {
              event_type: "BOOKING_INIT",
              user_id: userId,
              room_id,
              booking_time,
            };
            sendMessage(socketPayload);
            console.log("WebSocket connection opened");
          };
    
          socket.current.onmessage = (event) => {
            const data = JSON.parse(event.data);
            handleSocketData(data.message);
          };
          socket.current.onclose = (event) => {
            console.log("Socket Disconnected ", event);
          };
    
          return () => socket.current.close();
        }
      }, [bookInput.booking_time]);

    useEffect(() => {
        if (room?.id) {
          getRoomAvailable(room.id)
            .then((resp) => {
              const { results } = resp.data;
              setAvailableData(results);
              const bookedDate = results
                .filter(
                  (item) =>
                    item.status === "Booked" &&
                    item.booking_time === bookInput.booking_time
                )
                .map((item) => item.booking_date);
              setBookedDisableDateList([...bookedDate]);
            })
            .catch((error) => {
              if (error.response) {
                console.log(error.response.data.message);
              } else {
                console.log(error);
              }
            });
        }
      }, [room?.id, bookInput.booking_time]);

      useEffect(() => {
        const handleBeforeUnload = (event) => {
          if (bookInput.booking_date && showConfirmPage) {
            const socketPayload = {
              event_type: "BOOKING_CANCEL",
              user_id: userId,
              room_id: bookInput.room_id,
              booking_date: bookInput.booking_date
                ? format(bookInput.booking_date, "yyyy-MM-dd")
                : "",
              booking_time: bookInput.booking_time,
            };
            sendMessage(socketPayload);
          }
        };
    
        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => {
          window.removeEventListener("beforeunload", handleBeforeUnload);
        };
      }, [bookInput.booking_date, showConfirmPage]);
    
      useEffect(() => {
        if (calendarRef.current) {
          const calendarElement = calendarRef.current;
          if (window.bootstrap && window.bootstrap.Tooltip) {
            const tooltipTriggerList = [].slice.call(
              calendarElement.querySelectorAll('[data-bs-toggle="tooltip"]')
            );
            tooltipTriggerList.map(function (tooltipTriggerEl) {
              return new window.bootstrap.Tooltip(tooltipTriggerEl);
            });
          }
        }
      }, [availableData]);

      const handleTimeChange = (e) => {
        const value = e.target.value;
        setBookInput({ ...bookInput, booking_time: value });
        setBookedDisableDateList(
          availableData
            .filter(
              (item) => item.status === "Booked" && item.booking_time === value
            )
            .map((item) => item.booking_date)
        );
      };

      const getDateStatus = (date, slotType) => {
        const found = availableData.find((item) => {
          const slotDate = new Date(item.booking_date);
          return (
            slotDate.toDateString() === date.toDateString() &&
            item.booking_time === slotType
          );
        });
        return found
          ? {
            status: found.status,
            username: `${found.booking_user?.user?.first_name} ${found.booking_user?.user?.last_name}`,
          }
          : {};
      };

      const handleDateChange = (selectedDates) => {
        if (selectedDates.length > 0) {
          setBookInput({ ...bookInput, booking_date: selectedDates[0] });
          setDateError(false);
        }
      };

    const handleSelectChange = (name, value, _) => {
        setBookInput({ ...bookInput, [name]: value });
      };
  return (
    <form onSubmit={(e) => handleBook(e)}>
                  <div className="card-data flex-fill flex-vertical">
                    {userRole === "User" ? (
                      <>
                        <div className=" mb-3 mb-sm-0">
                          <label htmlFor="booking_time">
                            <strong
                              style={{ fontSize: "0.85em" }}
                              className="me-1"
                            >
                              Booking time
                            </strong>
                            <span className="text-danger">*</span>
                          </label>
                          <div>
                            {room?.room_slot_type === "Full Day" && (
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="booking_time"
                                  id="wholeday"
                                  value="09:00 AM to 11:00 PM"
                                  checked={
                                    bookInput.booking_time ===
                                    "09:00 AM to 11:00 PM"
                                  }
                                  onChange={handleTimeChange}
                                />
                                <label
                                  className="form-check-label ml-2"
                                  htmlFor="wholeday"
                                >
                                  Full Day
                                </label>
                              </div>
                            )}
                            {room?.room_slot_type !== "Full Day" && (
                              <>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="booking_time"
                                    id="morning"
                                    value="09:00 AM to 03:00 PM"
                                    checked={
                                      bookInput.booking_time ===
                                      "09:00 AM to 03:00 PM"
                                    }
                                    onChange={handleTimeChange}
                                  />
                                  <label
                                    className="form-check-label ml-2"
                                    htmlFor="morning"
                                  >
                                    9 AM - 3 PM
                                  </label>
                                </div>
                                <div className="form-check form-check-inline ml-4">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="booking_time"
                                    id="evening"
                                    value="06:00 PM to 11:00 PM"
                                    checked={
                                      bookInput.booking_time ===
                                      "06:00 PM to 11:00 PM"
                                    }
                                    onChange={handleTimeChange}
                                  />
                                  <label
                                    className="form-check-label ml-2"
                                    htmlFor="evening"
                                  >
                                    6 PM - 11 PM
                                  </label>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                          <div className="row">
                        <div className="col-md-6">
                          <label htmlFor="booking_date">
                            <strong
                              style={{ fontSize: "0.85em" }}
                              className="me-1"
                            >
                              Booking Date
                            </strong>
                            <span className="text-danger">*</span>
                          </label>
                          <RenderCalendar
                            handleDateChange={handleDateChange}
                            bookingDate={bookInput.booking_date}
                            disableDateList={[
                              ...disableDateList,
                              ...bookedDisableDateList,
                            ]}
                            calendarRef={calendarRef}
                            getDateStatus={getDateStatus}
                            slotType = {bookInput.booking_time}
                            userRole= {userRole}
                          />
                          {dateError && (
                            <div
                              className="text-danger mt-1"
                              style={{ fontSize: "0.8rem" }}
                            >
                              Please select a date.
                            </div>
                          )}
                        </div>
                        <div className="col-md-6">
                              <label htmlFor="booking_purpose_id">
                                <strong
                                  style={{ fontSize: "0.85em" }}
                                  className="me-1"
                                >
                                 Booking Purpose
                                </strong>
                                <span className="text-gray-500">
                                  (Optional)
                                </span>
                              </label>
                              <DynamicSelectInput
                                parentClassName="mb-0 order"
                                setParentInputValue={handleSelectChange}
                                endpoint_name="booking_purpose"
                                name={"booking_purpose_id"}
                                id={"booking_purpose_id"}
                                placeholder={"Select Booking Purpose"}
                              />
                        </div>
                      </div>
                      </>
                    ) : (
                      <>
                      <div className="row">
                      <div className="col-md-6">
                          <label htmlFor="booking_time">
                            <strong
                              style={{ fontSize: "0.85em" }}
                              className="me-1"
                            >
                              Booking time
                            </strong>
                            <span className="text-danger">*</span>
                          </label>
                          <div>
                            {room?.room_slot_type === "Full Day" && (
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="booking_time"
                                  id="wholeday"
                                  value="09:00 AM to 11:00 PM"
                                  checked={
                                    bookInput.booking_time ===
                                    "09:00 AM to 11:00 PM"
                                  }
                                  onChange={handleTimeChange}
                                />
                                <label
                                  className="form-check-label ml-2"
                                  htmlFor="wholeday"
                                >
                                  Full Day
                                </label>
                              </div>
                            )}
                            {room?.room_slot_type !== "Full Day" && (
                              <>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="booking_time"
                                    id="morning"
                                    value="09:00 AM to 03:00 PM"
                                    checked={
                                      bookInput.booking_time ===
                                      "09:00 AM to 03:00 PM"
                                    }
                                    onChange={handleTimeChange}
                                  />
                                  <label
                                    className="form-check-label ml-2"
                                    htmlFor="morning"
                                  >
                                    9 AM - 3 PM
                                  </label>
                                </div>
                                <div className="form-check form-check-inline ml-4">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="booking_time"
                                    id="evening"
                                    value="06:00 PM to 11:00 PM"
                                    checked={
                                      bookInput.booking_time ===
                                      "06:00 PM to 11:00 PM"
                                    }
                                    onChange={handleTimeChange}
                                  />
                                  <label
                                    className="form-check-label ml-2"
                                    htmlFor="evening"
                                  >
                                    6 PM - 11 PM
                                  </label>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <label htmlFor="booking_date">
                              <strong
                                style={{ fontSize: "0.85em" }}
                                className="me-1"
                              >
                                Booking Date
                              </strong>
                              <span className="text-danger">*</span>
                            </label>
                            <RenderCalendar
                              handleDateChange={handleDateChange}
                              bookingDate={bookInput.booking_date}
                              disableDateList={[
                                ...disableDateList,
                                ...bookedDisableDateList,
                              ]}
                              calendarRef={calendarRef}
                              getDateStatus={getDateStatus}
                              slotType = {bookInput.booking_time}
                              userRole= {userRole}
                            />
                            {dateError && (
                              <div
                                className="text-danger mt-1"
                                style={{ fontSize: "0.8rem" }}
                              >
                                Please select a date.
                              </div>
                            )}
                          </div>
                      </div>
                        <div className="row">
                        <div className="col-md-6 mb-3 mb-sm-0">
                              <label htmlFor="booking_purpose_id">
                                <strong
                                  style={{ fontSize: "0.85em" }}
                                  className="me-1"
                                >
                                 Booking Purpose
                                </strong>
                                <span className="text-gray-500">
                                  (Optional)
                                </span>
                              </label>
                              <DynamicSelectInput
                                parentClassName="mb-0 order"
                                setParentInputValue={handleSelectChange}
                                endpoint_name="user_booking_purpose"
                                name={"booking_purpose_id"}
                                id={"booking_purpose_id"}
                                placeholder={"Select Booking Purpose"}
                              />
                        </div>
                          {userRole !== "User" && (
                            <div className="col-md-6 col-sm-12">
                              <label htmlFor="on_behalf_id">
                                <strong
                                  style={{ fontSize: "0.85em" }}
                                  className="me-1"
                                >
                                  On Behalf Member
                                </strong>
                                <span className="text-gray-500">
                                  (Optional)
                                </span>
                              </label>
                              <DynamicSelectInput
                                parentClassName="mb-0 order"
                                setParentInputValue={handleSelectChange}
                                endpoint_name="onbehalf_member"
                                name={"on_behalf_id"}
                                id={"on_behalf_id"}
                                placeholder={"Select Member"}
                              />
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  {!isMobile && (
                    <button
                      type="submit"
                      className="btn bg-black text-white w-full py-2"
                      disabled={isBookingLoading}
                    >
                      Proceed For Payment
                    </button>
                  )}
                </form>
  );
};

export default BookingForm;
