import React, { useState, useEffect } from "react";
import { getRoomList } from "../../services/RoomService.js";
import { useNavigate } from "react-router-dom";
import { Container, Button } from "react-bootstrap";
import Header from "../../components/Headers/Header.js";
import CalendarPopup from "../../components/Public&User/Component/PopUpPage/CalendarPopUp.js";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Pagination from "../../constant/Pagination.jsx";
import MaintenanceRoom from "./MaintenanceRoom.js";
import useWindowSize from "../../constant/WindowSize.jsx";
import "./Room.css";
import Loader from "../../components/Loader/Loader.js";
import NoResultFound from "../../constant/NoResultFound/NoResultFound.js";
import RoomTypeDropDown, {
  SortTypeDropDown,
} from "../BookingStatus/DropDown.js";

const Rooms = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [filter, setFilter] = useState({
    room_type: "",
  });
  const [sortBy, setSortBy] = useState("-created_at");
  const [data, setData] = useState([]);
  const [showMaintenance, setShowMaintenance] = useState(false);
  const navigate = useNavigate();
  const recordsPage = 24;

  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      const context = this;
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const debouncedSearch = debounce((query) => {
    setSearchQuery(query);
  }, 300);

  useEffect(() => {
    if (isLoading) return;

    setIsLoading(true);
    getRoomList(currentPage, recordsPage, searchQuery, filter.room_type, sortBy)
      .then((resp) => {
        setData(resp.data.results);
        setTotal(resp.data.count);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.log(error);
        }
        setIsLoading(false);
      });
  }, [currentPage, searchQuery, filter.room_type, sortBy]);

  const size = useWindowSize();
  const isMobile = size.width <= 1024;

  const handleAddRoomClick = () => {
    navigate("/add-room");
  };

  const handlePagination = (page) => {
    const new_page = page.selected + 1;
    setCurrentPage(new_page);
  };

  const handleMaintenanceClick = () => {
    setShowMaintenance(!showMaintenance);
  };

  return (
    <>
      <Header />
      <Container
        className="Container mt-sm-0 mt-7 mb-sm-0 mb-7 p-sm-2 p-1"
        fluid
      >
        {isMobile ? (
          <div className="d-flex align-items-center justify-content-between p-2">
            {!showMaintenance && (
              <>
                <button
                  className="btn ms-3 bg-black text-white"
                  onClick={handleAddRoomClick}
                >
                  Add Room
                </button>
              </>
            )}

            <button
              className="btn ms-3 bg-black text-white"
              onClick={handleMaintenanceClick}
            >
              {showMaintenance ? "Back to Rooms" : "Maintenance"}
            </button>
          </div>
        ) : (
          <div className="d-flex align-items-center justify-content-between p-2">
            <h3 className="mb-3 text-dark fw-bold pb-2">
              <i className="fas fa-door-open me-2"></i>
              {showMaintenance ? "Maintenance" : "Rooms"}
            </h3>
            <div
              className="d-flex justify-content-end align-items-center"
              style={{ width: "60%" }}
            >
              {!showMaintenance && (
                <>
                  <button
                    className="btn ms-3 bg-black text-white"
                    onClick={handleAddRoomClick}
                  >
                    Add Room
                  </button>
                </>
              )}

              <button
                className="btn ms-3 bg-black text-white"
                onClick={handleMaintenanceClick}
              >
                {showMaintenance ? "Back to Rooms" : "Maintenance"}
              </button>
            </div>
          </div>
        )}
        <div className="card p-sm-3 p-2">
          {showMaintenance ? (
            <MaintenanceRoom />
          ) : (
            <div className="row g-4 mb-4">
              <div className="container max" style={{ maxWidth: "100vw" }}>
                <div className="head">
                  <div className="dropdown-container mb-sm-0 mb-3">
                    <RoomTypeDropDown
                      setFilter={setFilter}
                      filter={filter}
                      setCurrentPage={setCurrentPage}
                      style={{
                        border: "1px solid black",
                      }}
                      className="me-3"
                    />
                    <SortTypeDropDown
                      setSortBy={setSortBy}
                      sortBy={sortBy}
                      style={{
                        border: "1px solid black",
                      }}
                    />
                  </div>
                  <hr />

                  <div className="member-search mb-3">
                    <div className="search-bar">
                      <input
                        type="text"
                        className="form-control search-input w-100"
                        placeholder="Search"
                        onChange={(e) => debouncedSearch(e.target.value)}
                      />
                      <i className="fas fa-search search-icon"></i>
                    </div>
                  </div>
                </div>
              </div>

              {isLoading ? (
                <div
                  className="text-center my-7"
                  style={{ width: "100%", height: "100%" }}
                >
                  <Loader />
                </div>
              ) : data ? (
                data.map((item, key) => (
                  <div
                    key={key}
                    className="col-lg-4 col-md-6 mb-4 wow fadeInUp"
                    data-wow-delay="0.1s"
                  >
                    <div
                      className="room-item rounded overflow-hidden bg-white"
                      style={{
                        transition: "transform 0.2s, box-shadow 0.2s",
                      }}
                    >
                      <div style={{ height: "250px" }} onClick={() => navigate(`/room/${item.id}`)}>
                        <Carousel
                          autoPlay={false}
                          interval={3000}
                          infiniteLoop={true}
                          showIndicators={true}
                          showThumbs={false}
                          swipeable={true}
                          renderArrowPrev={(clickHandler, hasPrev, label) =>
                            hasPrev && (
                              <button
                                type="button"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  clickHandler();
                                }}
                                aria-label={label}
                                className="absolute top-1/2 left-3 z-10 transform -translate-y-1/2 bg-black/60 text-white rounded-full p-2"
                                style={{ zIndex: 10 }}
                              >
                                ❮
                              </button>
                            )
                          }
                          renderArrowNext={(clickHandler, hasNext, label) =>
                            hasNext && (
                              <button
                                type="button"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  clickHandler();
                                }}
                                aria-label={label}
                                className="absolute top-1/2 right-3 z-10 transform -translate-y-1/2 bg-black/60 text-white rounded-full p-2"
                                style={{ zIndex: 10 }}
                              >
                                ❯
                              </button>
                            )
                          }
                        >
                          {item.roomphoto.map((photo, index) => (
                            <div key={index}>
                              <img

                                src={`${process.env.REACT_APP_BASE_URL}${photo.photo}`}
                                alt={item.name}
                                className="img-fluid"
                                style={{
                                  height: "250px",
                                  width: "100%",
                                  objectFit: "cover",
                                  filter: "brightness(90%)",
                                }}
                              />
                            </div>
                          ))}
                        </Carousel>
                      </div>

                      <div
                        className="p-3 border rounded shadow-sm"
                        style={{ backgroundColor: "rgb(231 234 239)" }}
                      >
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <h5
                            className="mb-0 text-truncate"
                            style={{ maxWidth: "70%" }}
                          >
                            {item.name}
                          </h5>
                          <h5 className="mb-0 text-black font-bold">
                            <strong>Rs.</strong> {item.price}
                          </h5>
                        </div>
                        <div className="d-flex mb-2">
                          <small className="border-end me-2 pe-2 text-muted">
                            {item.room_slot_type}
                          </small>
                          <small className="border-end me-2 pe-2 text-muted">
                            {item.room_type}
                          </small>
                        </div>
                        <p
                          className="text-body mb-2 small text-truncate"
                          style={{ maxHeight: "3em", overflow: "hidden" }}
                        >
                          {item.description}
                        </p>
                        <div className="d-flex justify-content-between align-items-center">
                          <Button
                            className="btn btn-sm btn-outline-dark rounded py-2 px-4"
                            onClick={() => navigate(`/room/${item.id}`)}
                            style={{ border: "none" }}
                          >
                            View Details
                          </Button>
                          <CalendarPopup
                            roomId={item ? item.id : null}
                            roomSlotType={item ? item.room_slot_type : null}
                            room={item}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <NoResultFound />
              )}
            </div>
          )}
        </div>
        <Pagination
          currentPage={currentPage}
          recordsPage={recordsPage}
          dataLength={total}
          handlePagination={handlePagination}
        />
      </Container>
    </>
  );
};

export default Rooms;
