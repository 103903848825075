import axiosInstance from "./AxiosInstance"; 

export function getAllSalesReportList(page, page_size, query, year, month) {
    return axiosInstance.get(
      `api/all_sales_report?page=${page}&page_size=${page_size}&query=${query}&year=${year}&month=${month}`
    );
} 

export function getDailySalesReportList(page, page_size, query, year, month ) {
  return axiosInstance.get(
    `api/daily_sales_report?page=${page}&page_size=${page_size}&query=${query}&year=${year}&month=${month}`
  );
}

export function getDiscountReportList(query, year, month ) {
  return axiosInstance.get(
    `api/all_discount_report?query=${query}&year=${year}&month=${month}`
  );
}

// export function getRefundReportList({ page, page_size, query, year, month }) {
//   const params = {
//     page,
//     page_size,
//     query,
//     year,
//     month
//   };
//   return axiosInstance.get("api/", { params });
// }
