import React, { useState } from "react";
import { Container } from "reactstrap";
import Header from "../../components/Headers/Header.js";
import { Tab, Nav } from "react-bootstrap";
import "./Payment.css";
import Tables from "./AllSalesReport.js";
import useWindowSize from "../../constant/WindowSize";
import DailySalesReport from "./DailyReport.js";
import DiscountReport from "./DiscountReport.js";

const Reports = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [activeKey, setActiveKey] = useState("allsalesreport");
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const size = useWindowSize();
  const isMobile = size.width <= 1024;

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const currentYear = new Date().getFullYear();
  const years = Array.from(
    { length: currentYear - 2000 + 1 },
    (_, i) => 2000 + i
  );

  return (
    <>
      <Header />
      <Container
        className="Container mt-sm-0 mt-7 mb-sm-0 mb-7 p-sm-2 p-1"
        fluid
      >
        <div className="d-flex flex-wrap align-items-center justify-content-between p-2">
          <h2 className="mb-2 text-dark fw-bold pb-2 ">
            <i className="fa-solid fa-chart-line p-1"></i>
            Reports
          </h2>
          <div className={`row align-items-end ${isMobile ? "w-100" : "w-50"}`}>
            <div className="col-md-3">
              <select
                className="form-control me-2"
                value={year}
                onChange={(e) => setYear(e.target.value)}
              >
                <option value="">Select Year</option>
                {years.map((y) => (
                  <option key={y} value={y}>
                    {y}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-3">
              <select
                className="form-control me-2"
                value={month}
                onChange={(e) => setMonth(e.target.value)}
              >
                <option value="">Select Month</option>
                <option value="1">January</option>
                <option value="2">February</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </select>
            </div>
            <div className="search-bar col-md-5">
              <input
                type="text"
                className="form-control search-input w-100"
                placeholder="Search..."
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <i className="fas fa-search search-icon"></i>
            </div>
          </div>
        </div>
        <div className="card">
          <Tab.Container
            activeKey={activeKey}
            onSelect={(k) => setActiveKey(k)}
          >
            <div className="d-flex justify-content-between align-items-center py-0">
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="allsalesreport" className="tabnav p-3">
                    <strong className="text-primary text-sm">
                      <i className="fas fa-file-alt mr-2"></i> All Sales Report
                    </strong>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="daywisesalesreport"
                    className="tabnav p-3"
                  >
                    <strong className="text-primary text-sm">
                      <i className="fas fa-calendar-check mr-2"></i> Day Wise
                      Sales Report
                    </strong>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="discountreport" className="tabnav p-3">
                    <strong className="text-primary text-sm">
                      <i className="fas fa-percent mr-2"></i> Discount Report
                    </strong>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="refundreport" className="tabnav p-3">
                    <strong className="text-primary text-sm">
                      <i className="fas fa-undo-alt mr-2"></i> Refund Report
                    </strong>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <div className="d-flex align-items-center">
                <div className="ms-auto">{/* <ExcelDownload /> */}</div>
              </div>
            </div>
            <Tab.Content>
              <Tab.Pane eventKey="allsalesreport">
                {activeKey === "allsalesreport" && (
                  <Tables searchQuery={searchQuery} year={year} month={month} />
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="daywisesalesreport">
                {activeKey === "daywisesalesreport" && (
                  <DailySalesReport
                    searchQuery={searchQuery}
                    year={year}
                    month={month}
                  />
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="discountreport">
                {activeKey === "discountreport" && (
                  <DiscountReport
                    searchQuery={searchQuery}
                    year={year}
                    month={month}
                  />
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="refundreport">
                <div>Refund</div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </Container>
    </>
  );
};

export default Reports;
