import React, { useState, useEffect } from "react";
import TableNoResultFound from "../../constant/NoResultFound/TableNoResultFound.js";
import Pagination from "../../constant/Pagination.jsx";
import { Card, CardFooter, Table } from "reactstrap";
import { getDiscountReportList } from "../../services/ReportService.js";

const recordsPage = 20;

const DiscountReport = ({ searchQuery, year, month }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    getDiscountReportList( searchQuery, year, month)
      .then((resp) => {
        setData(resp.data.results);
        setTotal(resp.data.count);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.log(error);
        }
        setIsLoading(false);
      });
  }, [ searchQuery, year, month]);

  const handlePagination = (page) => {
    setCurrentPage(page.selected + 1);
  };

  return (
    <div className="px-0" id="table-to-download">
      <Card className="shadow">
        <Table className="table-component__table align-items-center table-flush" responsive>
          <thead className="booking-table-head disable-select">
            <tr className="booking-table-header">
              <th scope="col">Date</th>
              <th scope="col">Total</th>
              <th scope="col">Total GST</th>
              <th scope="col">Total Discount</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td className="text-center my-7" colSpan="4">
                  <div style={{ width: "100%", height: "100%" }}>
                    <div className="spinner-grow text-black me-2" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </td>
              </tr>
            ) : data.discounts?.length > 0 ? (
              data.discounts.map((item, index) => (
                <tr key={index} className="booking-table-row">
                  <td>{item?.payment_source}</td>
                  <td>{item?.total_sales}</td>
                  <td>{item?.total_gst}</td>
                  <td>{item?.total_discount}</td>
                </tr>
              ))
            ) : (
              <TableNoResultFound />
            )}
          </tbody>
        </Table>
        <CardFooter className="py-4">
          <Pagination
             currentPage={currentPage}
             recordsPage={recordsPage}
             dataLength={total}
             handlePagination={handlePagination}
          />
        </CardFooter>
      </Card>
    </div>
  );
};

export default DiscountReport;
