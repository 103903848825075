import React, { useState, useEffect, useRef, useCallback } from "react";
import { connect } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import { format, parseISO } from "date-fns";
import "./BookingPage.css";
import Header from "../../../Headers/Header";
import useWindowSize from "../../../../constant/WindowSize";
import AdminPaymentDetails from "./AdminPaymentDetails";
import { FaAngleRight } from "react-icons/fa";
import CouponPage from "./CouponPage";
import UserPaymentMode from "./UserPaymentMode";
import { Container } from "react-bootstrap";
import PriceTable from "./PriceTable";
import BookingForm from "./BookingForm";

const BookingPage = ({ userRole, userId, orderId }) => {
  const [isBookingLoading, setIsBookingLoading] = useState(false);
  const [bookInput, setBookInput] = useState({
    room_id: "",
    booking_time: "",
    booking_date: "",
    on_behalf_id: "",
  });
  const [bookedDisableDateList, setBookedDisableDateList] = useState([]);
  const [showConfirmPage, setShowConfirmPage] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [showCouponPage, setShowCouponPage] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [isApplyDisabled, setIsApplyDisabled] = useState(true);
  const calendarRef = useRef(null);
  const socket = useRef(null);
  const scrollRef = useRef(0);
  const location = useLocation();
  const room = location.state?.room || {};

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const size = useWindowSize();
  const isMobile = size.width <= 1024;

  const sendMessage = (data) => {
    if (socket.current?.readyState === WebSocket.OPEN) {
      socket.current.send(JSON.stringify(data));
    }
  };

  const resetBooking = useCallback(() => {
    setShowConfirmPage(false);
    setBookInput({
      room_id: room?.id,
      booking_time:
        room?.room_slot_type === "Full Day"
          ? "09:00 AM to 11:00 PM"
          : "09:00 AM to 03:00 PM",
      booking_date: "",
      on_behalf_id: "",
    });
  }, [room]);

  useEffect(() => {
    resetBooking();
  }, [room, resetBooking]);

  const handleBook = (e) => {
    e.preventDefault();
    if (!bookInput.booking_date) {
      setDateError(true);
      return;
    }
    setShowConfirmPage(true);
    const { room_id, booking_date, booking_time } = bookInput;
    const socketPayload = {
      event_type: "BOOKING_START",
      user_id: userId,
      room_id,
      booking_date: format(booking_date, "yyyy-MM-dd"),
      booking_time,
    };
    sendMessage(socketPayload);
  };

  const handleCancelBook = (e) => {
    e.preventDefault();
    setShowConfirmPage(false);
    const { room_id, booking_date, booking_time } = bookInput;
    const socketPayload = {
      event_type: "BOOKING_CANCEL",
      user_id: userId,
      room_id,
      booking_date: format(booking_date, "yyyy-MM-dd"),
      booking_time,
    };
    sendMessage(socketPayload);
  };

  const handleShowCoupons = (e) => {
    e.preventDefault();
    scrollRef.current = window.scrollY;
    setShowCouponPage(true);
    setTimeout(() => {
      window.scrollTo(0, scrollRef.current);
    }, 0);
  };

  const handleCouponSelect = (coupon) => {
    setSelectedCoupon(coupon);
    setIsApplyDisabled(false);
  };

  return (
    <>
      {userRole !== "User" && <Header />}
      <Container
        fluid
        className={`booking-container ${
          userRole === "User" ? "my-7 px-sm-5 px-0" : "my-7"
        }`}
      >
        <div className="row p-2 mb-3">
          <div className="col-md-8 col-sm-12 mb-3">
            <div className="card p-3">
              <div className="table-responsive">
                <table className="table table-borderless table-shopping-cart">
                  <thead className="text-muted">
                    <tr className="small text-uppercase">
                      <th scope="col">Room</th>
                      <th scope="col" width="200">
                        Details
                      </th>
                      {!isMobile && (
                        <th scope="col" width="150">
                          Price
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div
                          className="mb-3"
                          style={{
                            height: "auto",
                            width: "100%",
                          }}
                        >
                          <img
                            src={`${process.env.REACT_APP_BASE_URL}${room.roomphoto[0]?.photo}`}
                            alt={room.name}
                            className="img-fluid"
                            style={{ maxHeight: "200px", objectFit: "cover" }}
                          />
                        </div>
                      </td>
                      <td>
                        <figcaption className="info">
                          <Link
                            to={`/room-details/${room.id}`}
                            className="title text-dark"
                            data-abc="true"
                          >
                            {room?.name}
                          </Link>
                          <p className="text-muted small">
                            Type: {room?.room_type}
                          </p>
                        </figcaption>
                      </td>
                      {!isMobile && (
                        <td>
                          <div className="price-wrap">
                            <var className="price">
                              ₹{room?.price.toFixed(2)}
                            </var>
                          </div>
                        </td>
                      )}
                    </tr>
                  </tbody>
                </table>
              </div>
              {showConfirmPage ? (
                !isMobile && (
                  <div className="payment-methods-container p-4 rounded shadow">
                    <h3 className="payment-title text-center mb-4">
                      Select Payment Method
                    </h3>
                    {userRole !== "User" ? (
                      <AdminPaymentDetails
                        roomId={room?.id}
                        room={bookInput}
                        coupon={selectedCoupon}
                        isMobile={isMobile}
                        handleCancelBook={handleCancelBook}
                      />
                    ) : (
                      <UserPaymentMode
                        orderId={orderId}
                        handleCancelBook={handleCancelBook}
                        coupon={selectedCoupon}
                        setShowConfirmPage={setShowConfirmPage}
                        isMobile={isMobile}
                        bookInput={bookInput}
                        room={room}
                      />
                    )}
                  </div>
                )
              ) : (
                <BookingForm
                  setBookInput={setBookInput}
                  userId={userId}
                  bookedDisableDateList={bookedDisableDateList}
                  setBookedDisableDateList={setBookedDisableDateList}
                  dateError={dateError}
                  setDateError={setDateError}
                  showConfirmPage={showConfirmPage}
                  sendMessage={sendMessage}
                  calendarRef={calendarRef}
                  socket={socket}
                  handleBook={handleBook}
                  bookInput={bookInput}
                  room={room}
                  isMobile={isMobile}
                  userRole={userRole}
                />
              )}
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className="card shadow mb-3">
              <div className={`card-body ${showCouponPage ? "mb--4" : "mb-0"}`}>
                <label htmlFor="coupon_code" className="d-block mt-2">
                  <strong className="me-1">Do you have a coupon code?</strong>
                  <span className="text-gray-500">(Optional)</span>
                </label>
                <div className="d-flex mt-1 mb-3">
                  <input
                    type="text"
                    id="coupon_code"
                    className="form-control bg-transparent text-black flex-grow-1"
                    value={selectedCoupon?.code || ""}
                    readOnly
                  />
                  {selectedCoupon && (
                    <button
                      type="button"
                      className="ml-2 btn btn-danger"
                      onClick={() => {
                        setSelectedCoupon(null);
                        setIsApplyDisabled(true);
                      }}
                    >
                      &times;
                    </button>
                  )}
                </div>
                <span
                  className="d-flex text-sm cursor-pointer text-blue align-items-center"
                  onClick={handleShowCoupons}
                >
                  {showCouponPage ? "Hide All Coupons" : "View All Coupons"}
                  <FaAngleRight className="ms-2" />
                </span>
              </div>
              {showCouponPage && (
                <CouponPage
                  setShowCouponPage={setShowCouponPage}
                  onCouponSelect={handleCouponSelect}
                  price={room.price.toFixed(2)}
                  userRole={userRole}
                />
              )}
            </div>
            <PriceTable room={room} selectedCoupon={selectedCoupon} />
            {isMobile && showConfirmPage && (
              <div className="card shadow mt-3">
                <div className="payment-methods-container p-4 rounded shadow">
                  <h3 className="payment-title text-center mb-4">
                    Select Payment Method
                  </h3>

                  {!isMobile && userRole !== "User" ? (
                    <AdminPaymentDetails
                      roomId={room?.id}
                      room={bookInput}
                      coupon={selectedCoupon}
                      isMobile={isMobile}
                      handleCancelBook={handleCancelBook}
                    />
                  ) : (
                    <UserPaymentMode
                      orderId={orderId}
                      handleCancelBook={handleCancelBook}
                      coupon={selectedCoupon}
                      setShowConfirmPage={setShowConfirmPage}
                      isMobile={isMobile}
                      bookInput={bookInput}
                      room={room}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        {isMobile && !showConfirmPage && (
          <button
            type="submit"
            className="btn bg-black text-white w-full py-2"
            disabled={isBookingLoading}
            onClick={(e) => handleBook(e)}
          >
            Proceed For Payment
          </button>
        )}
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  userRole: state.auth.user?.role || "User",
  userId: state.auth.user?.id || "",
  orderId: state.auth.orderId,
});

export default connect(mapStateToProps)(BookingPage);
